import { ulid } from "ulid";
import React, { createContext, useContext, useState } from "react";
import { MqttProvider } from "./use_mqtt";
import { BentoWSProvider } from "./use_bentows";
import { WebSocketEventProvider } from "./events";
import {
  IWebSocketAdapter,
  WebSocketOnConnectHandler,
  WebSocketPublishFuncType,
  WebSocketSubscribeFuncType,
} from "./common";

export type WebSocketConnectionStatuses =
  | "connected"
  | "reconnecting"
  | "closed"
  | "offline"
  | "pending";

export type WebSocketImplementation = "unknown" | "iot" | "bento";

export type WebSocketProviderProps = {
  children: React.ReactNode;
  userId?: string;
  iotApiUrl: string;
  iotWssUrl: string;
  bentoWsApiUrl: string;
  bentoWsWssUrl: string;
  sessionId: string;
  correlationId: string;
  implementation: WebSocketImplementation;
};

export type WebSocketProviderContextSetter<T> = React.Dispatch<
  React.SetStateAction<T>
>;

export type WebSocketProviderContextProps = {
  connectionStatus: WebSocketConnectionStatuses;
  setConnectionStatus: WebSocketProviderContextSetter<WebSocketConnectionStatuses>;
  connectionId: string | undefined;
  setConnectionId: WebSocketProviderContextSetter<string | undefined>;
  implementation: WebSocketImplementation;
  apiUrl: string;
  wssUrl: string;
  wsAdapter: IWebSocketAdapter;
};

class DefaultWebSocketAdapter implements IWebSocketAdapter {
  private _publish: WebSocketPublishFuncType;
  private _subscribe: WebSocketSubscribeFuncType;
  private _onConnect: WebSocketOnConnectHandler;

  constructor() {
    this._publish = () => Promise.resolve();
    this._subscribe = () => Promise.resolve({ success: "", topic: "" });
    this._onConnect = async () => {
      console.log(
        "[tako] use_websocket.tsx: DefaultWebSocketAdapter: default onConnect called"
      );
    };
  }

  get publish() {
    return this._publish;
  }

  set publish(func: WebSocketPublishFuncType) {
    this._publish = func;
  }

  get subscribe() {
    return this._subscribe;
  }

  set subscribe(func: WebSocketSubscribeFuncType) {
    this._subscribe = func;
  }

  get onConnect() {
    return this._onConnect;
  }

  set onConnect(func: WebSocketOnConnectHandler) {
    this._onConnect = func;
  }
}

const defaultContext: WebSocketProviderContextProps = {
  connectionStatus: "closed",
  implementation: "unknown",
  setConnectionStatus: () => {},
  connectionId: undefined,
  setConnectionId: () => {},
  apiUrl: "",
  wssUrl: "",
  wsAdapter: new DefaultWebSocketAdapter(),
};

const WebSocketProviderContext =
  createContext<WebSocketProviderContextProps>(defaultContext);

export const WebSocketProvider = ({
  children,
  userId,
  iotApiUrl,
  iotWssUrl,
  implementation,
  bentoWsApiUrl,
  bentoWsWssUrl,
  sessionId,
  correlationId,
}: WebSocketProviderProps) => {
  console.log("[tako][comp] use_websocket.tsx: WebSocketProvider invoked...");

  const wsAdapter: IWebSocketAdapter = new DefaultWebSocketAdapter();

  wsAdapter.publish = async () => {
    console.log("use_websocket.tsx: publish(): blank wsAdapter func");
  };

  const [connectionStatus, setConnectionStatus] =
    useState<WebSocketConnectionStatuses>("closed");

  const [connectionId, setConnectionId] = useState<string | undefined>(
    undefined
  );

  const value: WebSocketProviderContextProps = {
    connectionStatus,
    setConnectionStatus,
    connectionId,
    setConnectionId,
    implementation,
    apiUrl: implementation === "bento" ? bentoWsApiUrl : iotApiUrl,
    wssUrl: implementation === "bento" ? bentoWsWssUrl : iotWssUrl,
    wsAdapter,
  };

  return (
    <WebSocketProviderContext.Provider value={value}>
      {implementation === "iot" ? (
        <MqttProvider
          apiUrl={iotApiUrl}
          correlationId={correlationId}
          hasIotCredentialsCaching={false}
          iotWssUrl={iotWssUrl}
          sessionId={sessionId}
          userId={userId}
          wsAdapter={wsAdapter}
        >
          <WebSocketEventProvider
            implementation="iot"
            connectionStatus={connectionStatus}
            userId={userId}
            connectionId={connectionId}
            wsAdapter={wsAdapter}
          >
            {children}
          </WebSocketEventProvider>
        </MqttProvider>
      ) : implementation === "bento" ? (
        <BentoWSProvider
          apiUrl={bentoWsApiUrl}
          iotWssUrl={bentoWsWssUrl}
          userId={userId}
          sessionId={sessionId}
          correlationId={correlationId}
          wsAdapter={wsAdapter}
        >
          <WebSocketEventProvider
            implementation="bento"
            connectionStatus={connectionStatus}
            userId={userId}
            connectionId={connectionId}
            wsAdapter={wsAdapter}
          >
            {children}
          </WebSocketEventProvider>
        </BentoWSProvider>
      ) : (
        <div></div>
      )}
    </WebSocketProviderContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketProviderContext);

import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";
import { useUpdateEffect } from "usehooks-ts";

import { TAKO_API, IOT_WSS_URL } from "../constants";
import useIot, { IoTConnectionStatuses } from "./use_iot";
import {
  IWebSocketAdapter,
  SubscribeAsyncFunctionCb,
  SubscribeAsyncFunctionErrorCb,
  SubscribeFunctionCb,
  SubscribeFunctionErrorCb,
} from "./common";

type MqttContextProps = {
  apiEndpoint?: string;
  status: IoTConnectionStatuses;
  subscribeSync?: ReturnType<typeof useIot>["subscribeSync"];
  unsubscribeSync?: ReturnType<typeof useIot>["unsubscribeSync"];
  publish?: ReturnType<typeof useIot>["publish"];
  parentSubscribe?: (
    topic: string,
    key: string,
    cb: SubscribeFunctionCb
  ) => void;
  parentUnsubscribe?: (topic: string, key: string) => void;
};

export interface MqttProviderProps {
  iotWssUrl?: string;
  apiUrl?: string;
  userId?: string;
  sessionId?: string;
  correlationId?: string;
  hasIotCredentialsCaching?: boolean;
  wssImplementation?: "iot" | "bento";
  wsAdapter: IWebSocketAdapter;
}

export const MqttContext = createContext<MqttContextProps>({
  status: "closed",
});

export function MqttProvider({
  children,
  ...props
}: { children: ReactNode } & MqttProviderProps) {
  const apiEndpoint = `${props.apiUrl || TAKO_API}`;
  const {
    iotStatus,
    subscribeSync,
    unsubscribeSync,
    publish: iotPublish,
  } = useIot({
    apiEndpoint,
    wssUrl: props.iotWssUrl || IOT_WSS_URL,
    userId: props.userId,
  });

  console.log("[tako][comp] use_mqtt.tsx: MqttProvider invoked: ", {
    iotStatus,
  });

  const [hasCredentials, setHasCredentials] = useState(false);

  const publish = async (topic: string, payload: unknown) => {
    const ret = await iotPublish(topic, JSON.stringify(payload));
    return ret;
  };

  if (props.wsAdapter) {
    props.wsAdapter.publish = publish;
  }

  const values = useMemo(
    () => ({
      apiEndpoint,
      status: iotStatus,
      subscribeSync,
      unsubscribeSync,
      publish,
    }),
    [iotStatus]
  );

  return <MqttContext.Provider value={values}>{children}</MqttContext.Provider>;
}

export function useIotSubscribeAsyncCallback(
  topic: string,
  enabled: boolean,
  cb: SubscribeAsyncFunctionCb,
  deps?: Array<unknown>,
  errCb?: SubscribeAsyncFunctionErrorCb
) {
  const {
    status: iotStatus,
    subscribeSync: iotSubscribeSync,
    unsubscribeSync: iotUnsubscribeSync,
  } = useContext(MqttContext);

  const dependencyList = Array.isArray(deps)
    ? [...deps, iotStatus]
    : [iotStatus];

  useEffect(() => {
    let subId: string | undefined;

    if (enabled && iotStatus === "connected" && iotSubscribeSync) {
      const func = async () => {
        subId = await iotSubscribeSync(
          topic,
          (payload) => {
            (async () => {
              await cb(payload);
            })();
          },
          (err) => {
            if (errCb) {
              (async () => {
                await errCb(err);
              })();
            }
          }
        );
      };

      func();
    }

    return () => {
      iotUnsubscribeSync &&
        (async () => {
          await iotUnsubscribeSync(topic, subId);
        })();
    };
  }, dependencyList);
}

// multiple channel topics
export function useIotMultiSubscribe(
  topic: string,
  cb: SubscribeFunctionCb,
  deps?: Array<unknown>,
  errCb?: SubscribeFunctionErrorCb
) {
  const ctx = useContext(MqttContext) as MqttContextProps;
  const dependencyList = Array.isArray(deps)
    ? [...deps, ctx.status]
    : [ctx.status];

  useUpdateEffect(() => {
    let subId: string | undefined;

    (async () => {
      if (ctx.subscribeSync) {
        subId = await ctx.subscribeSync(topic, cb, errCb);
      }
    })();

    return () => {
      ctx.unsubscribeSync && ctx.unsubscribeSync(topic, subId);
    };
  }, dependencyList);
}

// 1 channel topic
export function useIotEventSubscribe(
  topic: string,
  key: string,
  cb: SubscribeFunctionCb,
  deps: unknown[] = []
) {
  const { parentSubscribe, parentUnsubscribe } = useContext(
    MqttContext
  ) as MqttContextProps;

  useEffect(() => {
    (async () => {
      parentSubscribe && (await parentSubscribe(topic, key, cb));
    })();

    return () => {
      (async () => {
        parentUnsubscribe && (await parentUnsubscribe(topic, key));
      })();
    };
  }, deps);
}

export function useMqtt() {
  const ctx = useContext(MqttContext);

  return ctx;
}

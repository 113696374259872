import { ulid } from "ulid";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { fetchWithAuth } from "./fetchutils";
import { Theme, ThemeContext } from "./themes";
import { useState, useReducer } from "react";
import { useFeatureFlag } from "./feature_flag";
import { useTakoContext } from "../tako_context";
import { useWebSocket } from "../utils/use_websocket";
import { useWebSocketEventSubscribe } from "../utils/events";

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { implementation, apiUrl: apiEndpoint, wssUrl } = useWebSocket();

  const correlationId = ulid();

  console.log("theme_provider.tsx: ThemeProvider: ", {
    implementation,
    correlationId,
    apiEndpoint,
    wssUrl,
  });

  const { apiUrl } = useTakoContext();
  const { hasThemesEnabled, hasV2EndpointsEnabled } = useFeatureFlag();
  const [updatedTheme, setUpdatedTheme] = useState<Theme | null>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data: theme, refetch } = useQuery<{
    metadata: Theme;
    name: string;
  }>({
    queryKey: ["get_active_theme"],
    queryFn: async ({ queryKey }) => {
      if (!apiEndpoint) throw new Error("API ENDPOINT UNDEFINED");
      const url = hasV2EndpointsEnabled
        ? `${apiUrl}/v2/themes/active`
        : `${apiEndpoint}/api/v1/theme/active`;

      const res = await fetchWithAuth(url);

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    refetchOnWindowFocus: false,
    enabled: hasThemesEnabled,
  });
  const { interactivity, chat } = theme?.metadata ?? {
    interactivity: {
      colors: {
        highlight: "",
        background: "",
      },
      font: {
        style: "Poppins",
        size: "default",
        color: "#000",
      },
    },
    chat: {
      colors: {
        background: "",
      },
      font: {
        style: "Poppins",
        size: "default",
        color: "#000",
      },
    },
  };

  type ThemeListenerPayload = { data: { metadata: Theme } };

  useWebSocketEventSubscribe("theme_listener", "theme_listener", (payload) => {
    console.log("[tako] Theme Updating ...");

    let o: ThemeListenerPayload | undefined = undefined;

    if (typeof payload === "string") {
      console.warn(
        "theme_provider.tsx: WARNING: Received theme_listener payload of type string, CensoredChatPayload expected: ",
        payload
      );
      o = JSON.parse(payload) as ThemeListenerPayload;
    } else if ((payload as any).data) {
      console.log(
        "theme_provider.tsx: Received theme_listener payload of type CensoredChatPayload: ",
        payload
      );
      o = payload as ThemeListenerPayload;
    } else {
      console.log(
        "theme_provider.tsx: Received theme_listener payload that is an invald CensoredChatPayload: ",
        payload
      );
    }

    if (o) {
      setUpdatedTheme(o.data.metadata);
      refetch();
      forceUpdate();
    }
  });

  if (!hasThemesEnabled) return <>{children}</>;

  const contextValue = useMemo(
    () => ({
      interactivity: updatedTheme?.interactivity ?? interactivity,
      chat: updatedTheme?.chat ?? chat,
      isBentoTheme: theme?.name === "Bento Theme",
    }),
    [updatedTheme, interactivity, chat, theme?.name]
  );

  return (
    <ThemeContext.Provider
      key={updatedTheme ? "updatedTheme" : "defaultTheme"}
      value={contextValue}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function toAmPmFormat(timeStamp: number) {
  if (!isValidDate(timeStamp)) {
    return null;
  }
  const convertedTime = new Date(timeStamp);
  return convertedTime.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}

function isValidDate(dateString: number) {
  const d = new Date(dateString);
  return !isNaN(d.getTime());
}

import { useWebSocket } from "./use_websocket";
import { useIotSubscribeAsyncCallback } from "./use_mqtt";
import { useBentoWsSubscribe } from "./use_bentows";
import {
  IWebSocketAdapter,
  SubscribeAsyncFunctionCb,
  SubscribeAsyncFunctionErrorCb,
} from "./common";

type SubscribeFunctionCb = (payload: unknown) => void;
type SubscribeFunctionErrorCb = (error: Error) => void;

export function useWsSubscribe(
  topic: string,
  cb: SubscribeFunctionCb,
  deps?: Array<unknown>,
  errCb?: SubscribeFunctionErrorCb
) {
  const { implementation, connectionStatus } = useWebSocket();

  console.log("use_pubsub.tsx: useWsSubscribe(): ", { topic, implementation });

  if (implementation === "iot") {
    console.log(
      "use_pubsub.tsx: useWsSubscribe(): Calling useIotSubscribeAsyncCallback(): ",
      { topic, implementation }
    );
  }

  useIotSubscribeAsyncCallback(
    topic,
    implementation === "iot",
    async (payload: any) => {
      if (implementation === "iot") {
        cb(payload);
      }
    },
    deps,
    async (err) => {
      if (errCb) {
        errCb(err);
      }
    }
  );

  if (implementation === "bento") {
    console.log(
      "use_pubsub.tsx: useWsSubscribe(): Calling useBentoWsSubscribe(): ",
      { topic, implementation }
    );
  }

  useBentoWsSubscribe(
    topic,
    implementation === "bento",
    async (payload: any) => {
      if (implementation === "bento") {
        if (connectionStatus === "connected") {
          cb(payload);
        }
      }
    },
    deps,
    async () => {
      if (errCb) errCb;
    }
  );
}

export const adaptiveSubscribe = async (
  wsAdapter: IWebSocketAdapter,
  topic: string,
  cb: SubscribeAsyncFunctionCb,
  errCb?: SubscribeAsyncFunctionErrorCb,
  correlationId?: string,
  wso?: any
) => {
  const result = await wsAdapter.subscribe(
    topic,
    async (payload) => {
      try {
        await cb(payload);
      } catch (err: any) {
        if (errCb) {
          await errCb(err);
        }
      }
    },
    correlationId,
    wso
  );

  return result;
};

import { useState, useEffect, useRef } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import styled from "styled-components";
import { BiLock } from "react-icons/bi";

import InteractivityAnswer from "./interactivity_answer";
import { useThemeContext } from "../utils/themes";
import InteractivityLogo from "./interactivity_logo";

const QUESTION_FONT_SIZE = {
  sm: "14px",
  default: "16px",
  md: "18px",
  lg: "20px",
};

const PROMPT_FONT_SIZE = {
  sm: "12px",
  default: "14px",
  md: "16px",
  lg: "28px",
};

type InteractivityPredictionDisplayProps = {
  type: string;
  question: string;
  isLocked: boolean;
  interactivityOptions:
  | {
    optionId: string;
    optionText: string;
    imageUrl: string | null;
    isHighlighted: boolean | null;
    order: number;
  }[]
  | undefined;
  selectedAnswers: string[];
  handleAnswerClick: (option: { optionId: string; optionText: string }) => void;
  optionsProps?: { disabled?: boolean };
  logoUrl?: string;
};

interface ScrollableDivProps {
  themeBackgroundColor?: string;
  isMobile?: boolean;
}

const ScrollableDiv = styled.div<ScrollableDivProps>`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default function InteractivityPredictionDisplay(
  props: InteractivityPredictionDisplayProps
) {
  const { interactivity, isBentoTheme } = useThemeContext();
  const highlightColor = isBentoTheme
    ? "#AD7800"
    : interactivity?.colors.highlight;
  const fontFamily = interactivity?.font.style || "Poppins";
  const fontColor = isBentoTheme ? "#000" : interactivity?.font.color;
  const questionLength = props.interactivityOptions
    ? props.interactivityOptions?.length
    : 0;
  const displayImage = props.interactivityOptions
    ? !props.interactivityOptions.some((option) => option.optionText !== "Idle Group" && option.imageUrl === "")
    : true;
  const [minimize, setMinimize] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showButton, setShowButton] = useState(false);
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const div = scrollableDivRef.current;
    const checkScrollHeight = () => {
      if (div && div.scrollHeight > 250) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    if (div) {
      div.addEventListener("scroll", checkScrollHeight);
      checkScrollHeight();
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      if (div) {
        div.removeEventListener("scroll", checkScrollHeight);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [props.question]);

  const toggleMinimize = () => {
    setMinimize((prev) => !prev);
  };
  return (
    <>
      <div className="flex justify-between items-center px-4 py-[6px]">
        {/* <div className="flex items-center bg-[#AD7800] rounded-tr-lg rounded-bl-lg   pl-[17px] pr-[17px] pt-[5px] pb-[5px]">
          <span className="">{props?.type}</span>
          <BiBarChartSquare />
        </div> */}
      </div>
      <ScrollableDiv
        ref={scrollableDivRef}
        // style={{
        //   maxHeight: minimize ? "136px" : isMobile ? "260px" : "400px",
        // }}
        style={{
          maxHeight: minimize ? (isMobile ? "200px" : "400px") : "100px",
          transition: "max-height 400ms ease-in-out, opacity 300ms ease-in-out",
          transitionDelay: !minimize ? "150ms" : "0ms",
        }}
        className={`overflow-y-scroll grid grow grid-cols-1 px-4`}
      >
        {Array.isArray(props.selectedAnswers) && props.selectedAnswers.length > 0 && props.selectedAnswers[0] != '' && !props?.isLocked && (
          <div
            className={`mt-4 mb-3 `}
            style={{
              borderColor: highlightColor,
              backgroundColor: highlightColor,
              fontFamily,
              fontSize:
                PROMPT_FONT_SIZE[
                interactivity?.font.size as keyof typeof PROMPT_FONT_SIZE
                ] || "16px",
              color: "#FFFFFF",
            }}
          >
            <p className="text-center text-[12px] md:text-[16px]">
              Thanks, nakuha na namin ang vote mo!
            </p>
          </div>
        )}
        {!!props?.isLocked && (
          <div
            className={`mt-4 mb-3 `}
            style={{
              borderColor: highlightColor,
              backgroundColor: highlightColor,
              fontFamily,
              fontSize:
                PROMPT_FONT_SIZE[
                interactivity?.font.size as keyof typeof PROMPT_FONT_SIZE
                ] || "16px",
              color: "#FFFFFF",
            }}
          >
            <div className="flex justify-center items-center">
              <BiLock className="mr-2" />
              <p className="text-center text-[12px] md:text-[16px] text-white">
                Locked! You cannot change your answer
              </p>
            </div>
          </div>
        )}
        <div className="bg-[#FFF] bg-opacity-50 ">
          <p
            className="text-black font-semibold border-l-8 px-[10px] py-[12px] text-[14px] md:text-[16px]"
            style={{
              borderColor: highlightColor,
              fontFamily,
              fontSize:
                QUESTION_FONT_SIZE[
                interactivity?.font.size as keyof typeof QUESTION_FONT_SIZE
                ] || "16px",
              color: fontColor,
              fontWeight: 600,
            }}
          >
            {props?.question}
          </p>
        </div>


        {(props.interactivityOptions ?? [])
          .sort((curr, next) => curr.order - next.order)
          .map((option) => {
            if (option.optionText === "Idle Group") return null;

            return (
              <InteractivityAnswer
                displayImage={displayImage}
                key={option.optionId}
                img={option.imageUrl ?? ""}
                text={option.optionText}
                onClick={() => {
                  props.handleAnswerClick(option);
                }}
                isSelected={!!props.selectedAnswers.includes(option.optionId)}
                isLocked={!!props?.isLocked}
                isPrediction
                buttonProps={props.optionsProps}
              />
            );
          })}

        {props.logoUrl && <InteractivityLogo url={props.logoUrl} />}
      </ScrollableDiv>

      {showButton && (
        <div
          className="flex items-center justify-center text-[13px] font-extrabold cursor-pointer  gap-1 rounded-b-lg text-white"
          style={{
            backgroundColor: isBentoTheme ? "" : interactivity.colors.highlight,
          }}
          onClick={toggleMinimize}
        >
          {minimize ? (
            <>
              <BsChevronDown style={{ strokeWidth: "2" }} />
              Maximize
            </>
          ) : (
            <>
              <BsChevronUp style={{ strokeWidth: "2" }} />
              Minimize
            </>
          )}
        </div>
      )}
    </>
  );
}

import { createContext, useContext, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ulid } from "ulid";
import { v4 as uuidv4 } from "uuid";
import { Mutex } from "async-mutex";

import { useGetParticipantById } from "../queries/participants";
import { useGetMessages } from "../queries/messages";
import {
  generateBatchGetCurrUserReactionByMessageIdKey,
  generateBatchGetReactionByMessageIdKey,
} from "../queries/reactions";

import { useActivateParticipant } from "../mutations/participants";

import { useWebSocket } from "../utils/use_websocket";
import { createLocker } from "../utils/concurrency";
import { useWebSocketEventSubscribe } from "../utils/events";

import {
  CensoredChatPayload,
  Message,
  ParentMessage,
  PinnedChatPayload,
  ReactionsChatPayload,
  TogglePauseParticipantPayload,
  UncensoredChatPayload,
  UnpinnedChatPayload,
  UpdateChatPayload,
} from "./types";
import { useGetBannedWords } from "../queries/banned_words";
import { useScroll } from "../use_scroll";

export function ChatProvider({
  participantId,
  children,
}: {
  participantId?: string;
  children: React.ReactNode;
}) {
  const messageLogRef = useRef<HTMLDivElement | null>(null);
  const {
    implementation,
    connectionStatus: mqttStatus,
    connectionStatus,
    apiUrl: apiEndpoint,
    wssUrl,
  } = useWebSocket();
  const correlationId = ulid();

  console.log("chat_provider.tsx: ChatProvider: ", {
    implementation,
    connectionStatus,
    correlationId,
    apiUrl: apiEndpoint,
    wssUrl,
  });

  const ctx = useQueryClient();

  const messagesMutex = useRef<Mutex>(new Mutex());

  const messagesLocker = createLocker({
    isLocal: true,
    mutexObject: messagesMutex.current,
  });
  const MESSAGES_LOCKER = "messagesLocker";

  // component states
  const [messages, setMessages] = useState<Message[]>([]);
  const [parentMessage, setParentMessage] = useState<
    ParentMessage | undefined
  >();
  const [censoredList, setCensoredList] = useState<string[]>([]);

  // reusable function for making messages unique
  const makeUniqueMessagesList = (messages: Message[]) => {
    const result = Object.values(
      messages.reduce((acc, obj) => ({ ...acc, [obj.messageId]: obj }), {})
    ) as Message[];

    return result;
  };

  const { moveToBottom, ScrollDownButton } = useScroll({
    containerRef: messageLogRef,
  });

  // queries
  const getMessagesQuery = useGetMessages({
    onSuccess: (data) => {
      const formatMessages: Partial<Message>[] = (
        data?.messages?.data ?? []
      ).map((message) => ({
        userId: message.userId,
        message: message.content,
        name: message.userName,
        messageId: message.messageId,
        isCensored: message.isCensored,
        parentMessageId: message.parentMessageId,
        parentMessage: message.parentMessage,
        replyCount: message.replyCount,
        createdAt: message.createdAt,
        firstName: message.firstName,
        lastName: message.lastName,
        displayPhotoUrl: message.displayPhotoUrl,
        isHighlighted: message.isHighlighted,
      }));

      (async () => {
        await messagesLocker.lockThenRun(MESSAGES_LOCKER, async () => {
          setMessages([...messages, ...formatMessages] as Message[]);
        });
      })();

      setTimeout(() => {
        if (messageLogRef.current) {
          moveToBottom(true);
        }
      }, 1000);
    },
  });

  const getParticipantQuery = useGetParticipantById({
    participantId: participantId ?? "",
  });

  const { data: bannedWords } = useGetBannedWords();

  // mutations
  const activateParticipantMutation = useActivateParticipant({
    participantId: participantId ?? "",
    onSuccess: () => {
      ctx.invalidateQueries({
        queryKey: ["user", participantId],
      });
    },
  });

  // handlers
  const handleReplyClick = (parentMessage: ParentMessage) => {
    setParentMessage(parentMessage);
  };

  const cancelReply = () => {
    setParentMessage(undefined);
  };

  // websocket events
  useWebSocketEventSubscribe(
    "chat_stored",
    uuidv4(),
    (payload) => {
      console.info("[tako] chat_stored: ", payload);
      let data: Message | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: chat_stored payload is of type string, expected Message: ",
          payload
        );
        data = JSON.parse((payload as string) ?? "{}") as Message;
      } else if ((payload as any).messageId) {
        console.log(
          "use_chat.ts: chat_stored payload is of type Message: ",
          payload
        );
        data = payload as Message;
      } else {
        console.log(
          "use_chat.ts: chat_stored payload is an invalid Message: ",
          payload
        );
      }

      if (data) {
        (async () => {
          await messagesLocker.lockThenRun(MESSAGES_LOCKER, async () => {
            if (data) {
              setMessages(makeUniqueMessagesList([...messages, data]));
              setTimeout(() => moveToBottom(true), 100);
            }
          });
        })();
      }
    },
    [messages, mqttStatus]
  );

  useWebSocketEventSubscribe(
    "cleared_chat",
    uuidv4(),
    () => {
      (async () => {
        await messagesLocker.lockThenRun(MESSAGES_LOCKER, async () => {
          setMessages([]);
        });
      })();
    },
    []
  );

  useWebSocketEventSubscribe(
    "pinned_chat",
    uuidv4(),
    (payload) => {
      let o: PinnedChatPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: pinned_chat payload is of type string, expected PinnedChatPayload: ",
          payload
        );
        o = JSON.parse(payload) as PinnedChatPayload;
      } else if ((payload as any).data) {
        console.log(
          "use_chat.ts: pinned_stored payload is of type PinnedChatPayload ",
          payload
        );
        o = payload as PinnedChatPayload;
      } else {
        console.log(
          "use_chat.ts: pinned_stored payload is an invalid PinnedChatPayload: ",
          payload
        );
      }

      if (o) {
        const { data } = o;
        let newMessages: Message[] = [];
        let hasHighlightedMessage = false;
        for (const message of messages) {
          if (message.messageId === data.message.messageId) {
            newMessages = [...newMessages, { ...message, isHighlighted: true }];
            hasHighlightedMessage = true;
          } else {
            newMessages = [
              ...newMessages,
              {
                ...message,
                isHighlighted: false,
              },
            ];
          }
        }

        if (!hasHighlightedMessage) {
          newMessages = [data.message, ...newMessages];
        }

        (async () => {
          await messagesLocker.lockThenRun(MESSAGES_LOCKER, async () => {
            setMessages(newMessages);
          });
        })();
      }
    },
    [messages]
  );

  useWebSocketEventSubscribe(
    "unpinned_chat",
    uuidv4(),
    (payload) => {
      let o: UnpinnedChatPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: unpinned_chat payload is of type string, expected UnpinnedChatPayload: ",
          payload
        );
        o = JSON.parse(payload) as UnpinnedChatPayload;
      } else if ((payload as any).data) {
        console.log(
          "use_chat.ts: unpinned_stored payload is of type UnpinnedChatPayload: ",
          payload
        );
        o = payload as UnpinnedChatPayload;
      } else {
        console.log(
          "use_chat.ts: unpinned_stored payload is an invalid UnpinnedChatPayload: ",
          payload
        );
      }

      if (o) {
        const { data } = o;

        (async () => {
          await messagesLocker.lockThenRun(MESSAGES_LOCKER, async () => {
            setMessages(
              messages.map((message) => {
                if (message.messageId === data.messageId) {
                  return {
                    ...message,
                    isHighlighted: false,
                  };
                }

                return message;
              })
            );
          });
        })();
      }
    },
    [messages]
  );

  useWebSocketEventSubscribe(
    "censored_chat",
    uuidv4(),
    (payload) => {
      let o: CensoredChatPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: censored_chat payload is of type string, expected CensoredChatPayload: ",
          payload
        );
        o = JSON.parse(payload) as CensoredChatPayload;
      } else if ((payload as any).data) {
        console.log(
          "use_chat.ts: censored_chat payload is of type CensoredChatPayload: ",
          payload
        );
        o = payload as CensoredChatPayload;
      } else {
        console.log(
          "use_chat.ts: censored_chat payload is an invalid CensoredChatPayload: ",
          payload
        );
      }

      if (o) {
        setCensoredList([...censoredList, o.data.messageId]);
      }
    },
    [censoredList, messages]
  );

  useWebSocketEventSubscribe(
    "uncensored_chat",
    uuidv4(),
    (payload) => {
      let o: UncensoredChatPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: uncensored_chat payload is of type string, expected UncensoredChatPayload: ",
          payload
        );
        o = JSON.parse(payload) as UncensoredChatPayload;
      } else if ((payload as any).data) {
        console.log(
          "use_chat.ts: uncensored_chat payload is of type UncensoredChatPayload: ",
          payload
        );
        o = payload as UncensoredChatPayload;
      } else {
        console.log(
          "use_chat.ts: uncensored_chat payload is an invalid UncensoredChatPayload: ",
          payload
        );
      }

      if (o) {
        setCensoredList(
          censoredList.filter((msg) => msg !== o?.data.messageId)
        );
      }
    },
    [censoredList, messages]
  );

  useWebSocketEventSubscribe(
    "update_chat",
    uuidv4(),
    (payload) => {
      let o: UpdateChatPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: update_chat payload is of type string, expected UpdateChatPayload: ",
          payload
        );
        o = JSON.parse(payload) as UpdateChatPayload;
      } else if ((payload as any).userId) {
        console.log(
          "use_chat.ts: update_chat payload is of type UpdateChatPayload: ",
          payload
        );
        o = payload as UpdateChatPayload;
      } else {
        console.log(
          "use_chat.ts: uncensored_chat payload is an invalid UpdateChatPayload: ",
          payload
        );
      }

      if (o) {
        (async () => {
          await messagesLocker.lockThenRun(MESSAGES_LOCKER, async () => {
            setMessages((currMsgs) => {
              const newMessages = currMsgs.map((msg) => {
                if (msg.userId === o?.userId)
                  return {
                    ...msg,
                    firstName: o?.firstName || msg.firstName,
                    lastName: o?.lastName || msg.lastName,
                    displayPhotoUrl: o?.displayPhotoUrl || msg.displayPhotoUrl,
                  };

                return msg;
              });

              return newMessages;
            });
          });
        })();
      }
    },
    []
  );

  useWebSocketEventSubscribe(
    "reactions_chat",
    uuidv4(),
    (payload) => {
      let o: ReactionsChatPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: reactions_chat payload is of type string, expected ReactionsChatPayload: ",
          payload
        );
        o = JSON.parse(payload) as ReactionsChatPayload;
      } else if ((payload as any).action && (payload as any).data) {
        console.log(
          "use_chat.ts: reactions_chat payload is of type ReactionsChatPayload: ",
          payload
        );
        o = payload as ReactionsChatPayload;
      } else {
        console.log(
          "use_chat.ts: reactions_chat payload is an invalid ReactionsChatPayload: ",
          payload
        );
      }

      if (o) {
        ctx.invalidateQueries(
          generateBatchGetReactionByMessageIdKey(o.data.messageId)
        );

        ctx.invalidateQueries(
          generateBatchGetCurrUserReactionByMessageIdKey(
            o.data.messageId,
            o.data.userId
          )
        );
      }
    },
    []
  );

  useWebSocketEventSubscribe(
    "toggle_pause_participant",
    "toggle_pause_participant",
    (payload) => {
      let o: TogglePauseParticipantPayload | undefined = undefined;

      if (typeof payload === "string") {
        console.warn(
          "use_chat.ts: WARNING: toggle_pause_participant payload is of type string, expected TogglePauseParticipantPayload: ",
          payload
        );
        o = JSON.parse(payload) as TogglePauseParticipantPayload;
      } else if ((payload as any).data) {
        console.log(
          "use_chat.ts: toggle_pause_participant payload is of type TogglePauseParticipantPayload: ",
          payload
        );
        o = payload as TogglePauseParticipantPayload;
      } else {
        console.log(
          "use_chat.ts: toggle_pause_participant payload is an invalid TogglePauseParticipantPayload: ",
          payload
        );
      }

      if (o) {
        const { data: bannedUserData } = o;

        console.log(
          "[tako] Updating participant pause status ...",
          bannedUserData.status
        );

        if (participantId === bannedUserData.participantId) {
          ctx.invalidateQueries({
            queryKey: ["user", participantId],
          });
        }
      }
    },
    [participantId]
  );

  return (
    <ChatContext.Provider
      value={{
        getParticipantQuery: getParticipantQuery,
        getMessagesQuery: getMessagesQuery,
        activateParticipant: activateParticipantMutation.mutate,
        moveToBottom,
        ScrollDownButton,
        bannedWords: new Map(
          bannedWords
            ?.filter(({ bannedWord }) => !!bannedWord)
            .map(({ bannedWord }) => [bannedWord, bannedWord])
        ),
        refs: {
          messageLogRef,
        },
        states: {
          messages,
          censoredList,
          parentMessage,
          mqttStatus,
        },
        actions: {
          handleReplyClick,
          cancelReply,
        },
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export const ChatContext = createContext<Record<string, any>>({});

export function useChatContext() {
  return useContext(ChatContext);
}

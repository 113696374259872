export type EventBroadcasted =
  | "assigned_groups"
  | "scene_started"
  | "scene_ended"
  | "playlist_started"
  | "playlist_ended";

export type ScenePayload = {
  sceneId: string;
  instanceId: string;
  groupId?: string;
};
export type PlaylistPayload = {
  playlistId: string;
  instanceId: string;
  groupId?: string;
};
export type AssignedGroupsPayload = { [participantId: string]: string };

export function extractEventPayload(
  event:
    | "assigned_groups"
    | "scene_started"
    | "scene_ended"
    | "playlist_started"
    | "playlist_ended",
  payload: unknown
): ScenePayload | PlaylistPayload | AssignedGroupsPayload | undefined {
  let objPayload: any | undefined = undefined;

  if (typeof payload == "string") {
    console.warn(
      "event_utils.ts: extractEventPayload(): WARNING: Received payload is of type string, object expected: ",
      payload
    );

    try {
      objPayload = JSON.parse(payload);
    } catch (jsonErr: any) {
      console.warn(
        "event_utils.ts: extractEventPayload(): WARNING: Invalid JSON payload: ",
        payload
      );
      // Ignore
    }
  } else {
    objPayload = payload;
  }

  if (objPayload) {
    if (
      (event === "scene_started" || event === "scene_ended") &&
      objPayload.sceneId &&
      objPayload.instanceId
    ) {
      return objPayload as ScenePayload;
    }

    if (
      (event === "playlist_started" || event === "playlist_ended") &&
      objPayload.playlistId &&
      objPayload.instanceId
    ) {
      return objPayload as PlaylistPayload;
    }

    if (event === "assigned_groups") {
      return objPayload as AssignedGroupsPayload;
    }
  }

  return undefined;
}

export type InteractivityData =
  | {
      interactivityId: string;
      type: "POLL" | "QUIZ" | "TITLING" | "PREDICTION" | "ADS";
      sceneId: string;
    }
  | undefined;

export type EventReceivedFunction = (
  event: EventBroadcasted,
  payload: unknown
) => void;

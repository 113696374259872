export default function SignInButton({ onLogin }: { onLogin?: () => void }) {
  return (
    <div className="w-full p-4 mt-auto">
      <button
        className="w-full p-4 hover:bg-gray-100 active:scale-[.995] bg-white border-2 border-[#AAAAAA] rounded-xl text-[15px] font-semibold text-black"
        onClick={() => {
          if (onLogin) {
            onLogin();
          }
        }}
      >
        SIGN IN TO CHAT
      </button>
      <p className="text-center mt-4 text-xs text-[#5D5F63]">
        Your messages will be publicly available
      </p>
    </div>
  );
}

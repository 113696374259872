import { useEffect } from "react";
import { useAtom } from "jotai";

import PromptDisplay from "./prompt";
import Message_reply from "./message_reply";
import Message from "./message";

import { useChatContext } from "./chat_provider";
import {
  isPromptMessageStore,
  isPromptMissedSceneStore,
  isPromptStore,
} from "../atoms";

import { Message as MessageType } from "./types";

export default function Messages() {
  const [isPrompt, setIsPrompt] = useAtom(isPromptStore);
  const [isPromptMissedScene, setIsPromptMissedScene] = useAtom(
    isPromptMissedSceneStore
  );
  const [promptMessage] = useAtom(isPromptMessageStore);
  const {
    states,
    getParticipantQuery,
    actions,
    refs: { messageLogRef },
    ScrollDownButton,
  } = useChatContext();
  const participant = getParticipantQuery.data;
  const isLoading = getParticipantQuery.isLoading;
  const { handleReplyClick } = actions;

  useEffect(() => {
    console.log("IS MISSED SCENE PROMPT");
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (!participant?.participantId) {
      setIsPromptMissedScene(true);
    } else if (participant?.participantId && isPromptMissedScene) {
      timeoutId = setTimeout(() => {
        setIsPrompt(false);
        setIsPromptMissedScene(false);
      }, 10000);
    }

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
    };
  }, [participant?.participantId, isPromptMissedScene]);

  if (
    (!participant?.participantId ||
      (!isLoading && !participant.isFirstTimeUser)) &&
    states.mqttStatus === "connected"
  ) {
    return (
      <>
        <div
          className={`flex flex-col flex-grow h-0 overflow-y-auto overflow-x-hidden scroll-smooth scrollbar ${
            participant?.isFirstTimeUser ? "hidden" : ""
          }`}
          ref={messageLogRef}
        >
          <div
            className={`absolute top-0 z-[9696969]  px-4 py-2 flex justify-between w-full transition-all ease-in-out delay-300`}
          >
            {isPrompt && (
              <PromptDisplay
                handleTitlingClose={() => setIsPrompt(false)}
                content={
                  isPromptMissedScene
                    ? "Aww sayang, hindi mo naabutan ang laro na ito. Bawi next time!"
                    : promptMessage
                }
              />
            )}
          </div>

          {states.messages.map((message: MessageType, index: number) => (
            <div key={message.messageId + index}>
              {message.parentMessageId && !message?.isHighlighted && (
                <Message_reply
                  isCensored={
                    states.censoredList.includes(message.parentMessageId) ||
                    message.isCensored
                  }
                  messageId={message.parentMessageId}
                />
              )}
              <Message
                messageRef={undefined}
                isCensored={states.censoredList.includes(message.messageId)}
                isHighlighted={!!message?.isHighlighted}
                content={message.message}
                {...message}
                loggedInUserId={participant?.participantId ?? ""}
                parentMessageId={message.parentMessageId}
                createdAt={message.createdAt}
                handleReplyClick={handleReplyClick}
                displayPhotoUrl={message.displayPhotoUrl}
                reactions={message.reactions}
                userStatus={states.userStatus}
              />
            </div>
          ))}
        </div>

        <ScrollDownButton />
      </>
    );
  }
}

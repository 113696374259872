import { useThemeContext } from "../utils/themes";
type InteractivityTitlingDisplayProps = {
  title?: string;
  content: string;
  handleTitlingClose: () => void
};
import { AiOutlineDoubleRight } from "react-icons/ai";

export default function PromptDisplay({
  title,
  content,
  handleTitlingClose,
}: InteractivityTitlingDisplayProps) {
  const { interactivity, isBentoTheme } = useThemeContext();
  const backgroundColor = isBentoTheme
    ? "#EAAC21D9"
    : interactivity?.colors.background;
  const highlightColor = isBentoTheme
    ? "#8F6504"
    : interactivity?.colors.highlight;
  const fontFamily = interactivity?.font.style || "Poppins";
  const fontSize = interactivity?.font.size
  return (
    <div className="w-full">
      <div
        className="relative flex items-center justify-center min-h-[40px]"
        style={{
          backgroundColor,
        }}
      >
        <h2
          className="font-semibold text-lg"
          style={{
            color: highlightColor,
            fontFamily,
          }}
        >
          {title}
        </h2>
      </div>
      <div
        className="pb-4"
        style={{
          backgroundColor,
          filter: "brightness(130%)",
        }}
      >
        <div className="min-h-[80px] flex items-center justify-center">
          <p
            className="text-sm text-black m-6"
            style={{
              fontFamily,
              fontSize
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: content }}></span>
          </p>
        </div>
      </div>
      <div
        className="rounded-b-lg"
        style={{
          backgroundColor,
          filter: isBentoTheme ? "unset" : "brightness(115%)",
        }}
      >
        <span
          onClick={handleTitlingClose}
          className="flex justify-end  pr-5 pt-2  pb-2 text-md align-middle items-center"
          role="button"
          style={{
            color: highlightColor,
            fontFamily: interactivity?.font.style || "Poppins",
            fontSize
          }}
        >
          Dismiss
          <span className="font-[1700] text-[20px]">
            <AiOutlineDoubleRight />
          </span>
        </span>
      </div>
    </div>
  );
}

import { ulid } from "ulid";
import { Popover } from "@headlessui/react";
import Color from "color";
import { RiEmotionHappyLine } from "react-icons/ri";
import ReactionPopoverList from "./reaction_popover_list";
import { useThemeContext } from "../utils/themes";

export default function ReactionPopover(props: {
  loggedInUserId?: string;
  userId: string;
  messageId: string;
  currUserReaction?: {
    reaction: string;
    reactionId: string;
  };
  disabled?: boolean;
}) {
  const { chat: chatStyle, isBentoTheme } = useThemeContext();
  const chatBackground = chatStyle.colors.background;
  const reactButtonColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#ABC6D7"
      : Color(chatBackground).darken(0.7).hex();

  return (
    <Popover
      className="h-10 w-10 last:rounded-tr-md last:rounded-br-md group hover:bg-slate-200 border-2 z-[2]"
      style={{
        borderColor: reactButtonColor,
        fill: reactButtonColor,
      }}
    >
      <Popover.Button className="w-full h-full" disabled={props.disabled}>
        <RiEmotionHappyLine
          size={26}
          className="group-hover:fill-white m-1"
          style={{
            fill: isBentoTheme ? "#ABC6D7" : reactButtonColor,
          }}
        />
      </Popover.Button>
      <Popover.Panel className="relative z-10 -left-[360px] min-w-max">
        {({ close }) => (
          <ReactionPopoverList
            messageId={props.messageId}
            loggedInUserId={props.loggedInUserId}
            onClose={() => close()}
            currUserReaction={props.currUserReaction}
            correlationId={ulid()}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
}

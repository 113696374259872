import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithAuth } from "../utils/fetchutils";
import { useTakoContext } from "../tako_context";
import { generateBatchGetReactionByMessageIdKey } from "../queries/reactions";

export function useCreateReaction(messageId: string) {
  const { apiUrl } = useTakoContext();
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (payload: {
      messageId: string;
      reaction: string;
      userId: string;
      publishTo?: string;
      correlationId?: string;
    }) => {
      const res = await fetchWithAuth(`${apiUrl}/v2/reactions`, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      const json = await res.json();

      return json;
    },
    onSuccess() {
      client.invalidateQueries(
        generateBatchGetReactionByMessageIdKey(messageId)
      );
    },
  });
}

export function useUpdateReaction(messageId: string) {
  const { apiUrl } = useTakoContext();
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (payload: {
      messageId: string;
      reaction: string;
      userId: string;
    }) => {
      const res = await fetchWithAuth(
        `${apiUrl}/v2/reactions/${payload.messageId}`,
        {
          method: "PUT",
          body: JSON.stringify(payload),
        }
      );

      const json = await res.json();

      return json;
    },
    onSuccess() {
      client.invalidateQueries(
        generateBatchGetReactionByMessageIdKey(messageId)
      );
    },
  });
}

export function useDeleteReaction(messageId: string) {
  const { apiUrl } = useTakoContext();
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (payload: {
      messageId: string;
      reactionId: string;
      userId: string;
      publishTo?: string;
      correlationId?: string;
    }) => {
      const res = await fetchWithAuth(
        `${apiUrl}/v2/reactions/${payload.reactionId}`,
        {
          method: "DELETE",
          body: JSON.stringify(payload),
        }
      );

      const json = await res.json();

      return json;
    },
    onSuccess() {
      client.invalidateQueries(
        generateBatchGetReactionByMessageIdKey(messageId)
      );
    },
  });
}

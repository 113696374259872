import { RiWifiOffLine } from "react-icons/ri";
import { useChatContext } from "./chat_provider";

const DotsBounce = () => {
  return (
    <div className="flex flex-row pl-1">
      <div className="animate-[bounce_0.7s_ease-in-out_-0.3s_infinite] p-[2px]">
        .
      </div>
      <div className="animate-[bounce_0.7s_ease-in-out_-0.2s_infinite] p-[2px]">
        .
      </div>
      <div className="animate-[bounce_0.7s_ease-in-out_-0.1s_infinite] p-[2px]">
        .
      </div>
    </div>
  );
};

export default function ChatLoader() {
  const { states, getMessagesQuery } = useChatContext();
  const { mqttStatus } = states;

  if (!getMessagesQuery.isLoading && mqttStatus === "connected") return null;

  if (mqttStatus === "offline")
    return (
      <>
        Check you internet connection <RiWifiOffLine className="ml-2" />
      </>
    );

  return (
    <>
      Connecting to server <DotsBounce />
    </>
  );
}

import { useQuery } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import { CSSProperties, ReactNode, memo, useEffect, useState } from "react";
import Color from "color";
import styled from "styled-components";

import { useWebSocket } from "../utils/use_websocket";
import { clean } from "../utils/string";
import { CACHE_TIME, STALE_TIME } from "../constants";
import { toAmPmFormat } from "../utils/time_format";
import { useThemeContext } from "../utils/themes";
import { fetchWithAuth } from "../utils/fetchutils";
import { useTakoContext } from "../tako_context";
import { useFeatureFlag } from "../utils/feature_flag";
import { useBatchGetParentMessage } from "../queries/messages";
import MessageUsername from "./message_username";
import MessageUserInitials from "./message_user_initials";

type MessageProps = {
  isCensored?: boolean;
  messageId: string;
};

const getDisplayNameInitials = (
  firstName: string | undefined,
  lastName: string | undefined
): ReactNode => {
  const fn = firstName && firstName.length > 0 ? firstName[0] : "";
  const ln = lastName && lastName.length > 0 ? lastName[0] : "";
  return (
    <>
      <span className="uppercase">{fn}</span>
      <span className="uppercase">{ln}</span>
    </>
  );
};

const PROF_INITIALS_FONT_SIZES = {
  sm: "10px",
  default: "12px",
  md: "14px",
  lg: "16px",
};

const NAME_FONT_SIZES = {
  sm: "10px",
  default: "12px",
  md: "14px",
  lg: "16px",
};

const TIME_FONT_SIZES = {
  sm: "8px",
  default: "10px",
  md: "12px",
  lg: "14px",
};

type MessageWithLink = {
  isbentotheme: string;
  interstyle: {
    colors: {
      highlight: string;
    };
  };
};

const MessageWithLink = styled.p<MessageWithLink>`
  & a {
    color: ${({ isbentotheme, interstyle }: MessageWithLink) =>
      isbentotheme === "true" || interstyle.colors.highlight.at(0) !== "#"
        ? "blue"
        : Color(interstyle.colors.highlight).lighten(0.5).hex()} !important;
  }
`;

function MessageReply({ messageId, isCensored }: MessageProps) {
  const styles: CSSProperties = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "0.5rem",
  };
  const { implementation, apiUrl: apiEndpoint } = useWebSocket();

  /*
  console.log("message_reply.tsx: MessageReply: ", {
    implementation,
    apiEndpoint,
  });
  */

  const { apiUrl } = useTakoContext();
  const { hasV2EndpointsEnabled } = useFeatureFlag();
  const { data, isLoading } = useBatchGetParentMessage(messageId);

  // console.log("Message Reply", { data, isLoading });
  const [filter, setFilter] = useState<Map<string, string> | null>(null);
  const {
    chat: chatStyle,
    isBentoTheme,
    interactivity: interStyle,
  } = useThemeContext();
  const chatBackground = chatStyle.colors.background;
  const pictureBackgroundColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? Color("#94A1B299").lighten(0.45).hex()
      : Color(chatBackground).darken(0.5).hex();
  const replyLineColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#94A1B2"
      : Color(chatBackground).darken(0.5).hex();
  const usernameColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#616E7D"
      : Color(chatBackground).darken(0.5).hex();
  const timeColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#A3A9B7"
      : Color(chatBackground).darken(0.5).hex();
  const fontFamily = chatStyle.font.style || "Poppins";

  const { data: banned_words } = useQuery<{ bannedWord: string }[]>({
    queryFn: async () => {
      if (!apiEndpoint) throw new Error("API ENDPOINT UNDEFINED");
      const url = hasV2EndpointsEnabled
        ? `${apiUrl}/v2/messages/banned_words`
        : `${apiEndpoint}/api/v1/banned_words`;

      const res = await fetchWithAuth(url);

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
  });

  useEffect(() => {
    if (!!banned_words) {
      setFilter(
        new Map(
          banned_words
            .filter(({ bannedWord }) => !!bannedWord)
            .map(({ bannedWord }) => [bannedWord, bannedWord])
        )
      );
    }
  }, [banned_words]);

  const picture = (
    <div
      className={`flex-shrink-0 h-8 w-8 rounded-full mt-2 z-8`}
      style={{
        backgroundColor: pictureBackgroundColor,
      }}
    >
      <MessageUserInitials participantId={data?.data?.userId ?? ""} />
    </div>
  );
  let content = "";
  if (data && filter) {
    content = clean(data?.data?.content || "", filter);
  }
  const formattedTime = toAmPmFormat(data?.data?.createdAt ?? 0);

  if (isLoading) {
    return <>...</>;
  }

  return (
    <div className="w-full ml-4">
      <div className={`flex w-full  space-x-2 ml-8 items-center`}>
        <div className="relative right-3 bottom-1">
          <div
            className="border-l-2 border-t-2 border-solid rounded-tl-lg w-1/100 h-full pt-5 pr-5 pb-6 mt-2 absolute"
            style={{
              borderColor: replyLineColor,
            }}
          />
        </div>
        {picture}
        <div className="flex-1 break-words ">
          <MessageUsername participantId={data?.data?.userId ?? ""} />
          <span
            className="text-[10px] font-[450] ml-1"
            style={{
              color: timeColor,
              fontSize:
                TIME_FONT_SIZES[
                  chatStyle?.font?.size as keyof typeof TIME_FONT_SIZES
                ] || "10px",
              fontFamily,
            }}
          >
            {formattedTime}
          </span>
          <div className={"max-w-full"}>
            <div className="flex text-dark rounded-l-lg rounded-br-lg w-[90%] ">
              <MessageWithLink
                className="text-[13px] hover:text-[#DA830F]"
                style={{
                  ...styles,
                  fontFamily,
                  color:
                    isCensored || data?.data?.isCensored ? "red" : "#828282",
                }}
                interstyle={interStyle}
                isbentotheme={`${isBentoTheme}`}
              >
                {isCensored || data?.data?.isCensored ? (
                  "This message has been flagged inappropriate."
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </MessageWithLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(MessageReply);

import { useQuery } from "@tanstack/react-query";
import { useTakoContext } from "../tako_context";
import { fetchWithAuth } from "../utils/fetchutils";

export function useGetInstanceParticipantsById(participantId: string) {
  const { apiUrl } = useTakoContext();
  const baseUrl = `${apiUrl}/v2`;

  return useQuery<{ instanceId: string; playlistId: string; groupId: string }>({
    queryKey: ["get_participant_instancev2"],
    queryFn: async () => {
      const url = new URL(`${baseUrl}/instances/get_participant_instancev2`);
      url.searchParams.set("participantId", participantId);

      const res = await fetchWithAuth(url.toString());

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    enabled: !!participantId && participantId !== "",
  });
}

import { createContext, useContext, useState } from "react";
import { useFeatureFlag } from "./feature_flag";
import { useQuery } from "@tanstack/react-query";
import { fetchWithAuth } from "./fetchutils";

export type Theme = {
  interactivity: {
    logo?: string;
    colors: {
      highlight: string;
      background: string;
    };
    font: {
      style: string;
      size: string;
      color: string;
    };
  };
  chat: {
    backgroundImage?: {
      url: string;
      blur: boolean;
    };
    colors: {
      background: string;
    };
    font: {
      style: string;
      size: string;
      color: string;
    };
  };
  isBentoTheme: boolean;
};

export const ThemeContext = createContext<Theme>({
  interactivity: {
    colors: {
      highlight: "",
      background: "",
    },
    font: {
      style: "",
      size: "",
      color: "",
    },
  },
  chat: {
    colors: {
      background: "",
    },
    font: {
      style: "",
      size: "",
      color: "",
    },
  },
  isBentoTheme: true,
});

export function useThemeContext() {
  return useContext(ThemeContext);
}

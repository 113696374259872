/**
 * Splits the first occuring emoji from the rest of the string.
 *
 * examples:
 * - "👋 hello" => ["👋", " hello"]
 * - "hello" => ["", "hello"]
 * - "👋👋 hello" => ["👋", "👋 hello"]
 * - "hello 👋" => ["", "hello 👋"]
 *
 * @param str - the string to split
 * @returns string[] - the first element is the first emoji, the second element is the rest of the string
 */
export function splitFirstOccuringEmojis(str: string) {
  const emojiRegex =
    /([\u2700-\u27BF]|[\uE000-\uFFFD]|[\uD800-\uDBFF][\uDC00-\uDFFF])/;
  const match = str.match(emojiRegex);
  if (match) {
    return [match[0], str.slice(match[0].length)];
  }
  return ["", str];
}

/**
 * Mask the words with asterisk based on the set of regex/words
 *
 * @param checkString - the chat message
 * @param bannedWords - the banned words in Map Object
 */
export function clean(checkString: string, bannedWords: Map<string , string>) {
  const checkStringArray = checkString.split(" ");

  let newString: string[] = [];
  let skip: null | number = null;

  checkStringArray.forEach((word, index) => {
    if (!!skip && skip !== index) return;

    skip = null;

    for (let i = checkStringArray.length ; index < i ; i-- ) {
      const mapKey = checkStringArray.slice(index, i).join(" ");
      const mapVal = bannedWords.get(mapKey);
      if(mapVal) {
        for (const word of mapVal.split(" ")) newString.push('*'.repeat(word.length));
        skip = i;
        break;
      } else if ((i - index) === 1) {
        newString.push(word)
      }
    }
  });

  return newString.join(" ");
}

/**
 * Mask the words with asterisk based on the set of regex/words (to be removed after new function is stable)
 *
 * @param str - the string to mask
 * @param filter - the regex/words to be based
 */
export function oldClean(str: string, filter: string) {
  if (!filter) return str;

  const regex = new RegExp(filter, "g");
  return str
    .split(" ")
    .map((word) => {
      const regexResult = regex.exec(word);
      if (!!regexResult) {
        return word.replace(
          regex,
          regexResult[0]
            .split("")
            .map(() => "*")
            .join("")
        );
      }
      return word;
    })
    .join(" ");
}


import { useMutation } from "@tanstack/react-query";
import { fetchWithAuth } from "../utils/fetchutils";
import { useWebSocket } from "../utils/use_websocket";

export function useActivateParticipant({
  participantId,
  onSuccess,
}: {
  participantId: string;
  onSuccess?: () => void;
}) {
  const { apiUrl } = useWebSocket();

  return useMutation<
    { data: Array<{ userId: string; takoParticipantId: string }> },
    unknown
  >(
    async () => {
      if (!apiUrl) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(
        `${apiUrl}/api/v1/participants/${participantId}/activate`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    {
      onSuccess: onSuccess,
    }
  );
}

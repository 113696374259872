import { useThemeContext } from "../utils/themes";
import InteractivityLogo from "./interactivity_logo";
type InteractivityTitlingDisplayProps = {
  title: string;
  content: string;
  logoUrl?: string;
};

export default function InteractivityTitlingDisplay({
  title,
  content,
  logoUrl,
}: InteractivityTitlingDisplayProps) {
  const { interactivity, isBentoTheme } = useThemeContext();
  const backgroundColor = isBentoTheme
    ? "#EAAC21D9"
    : interactivity?.colors.background;
  const highlightColor = isBentoTheme
    ? "#8F6504"
    : interactivity?.colors.highlight;
  const fontFamily = interactivity?.font.style || "Poppins";
  const fontColor = interactivity?.font.color || "#000";

  return (
    <div className="w-full">
      <div
        className="relative flex items-center justify-center min-h-[40px]"
        style={{
          backgroundColor,
        }}
      >
        <h2
          className="font-semibold text-lg"
          style={{
            color: highlightColor,
            fontFamily,
          }}
        >
          {title}
        </h2>
      </div>
      <div
        className="pb-4"
        style={{
          backgroundColor,
          filter: "brightness(130%)",
        }}
      >
        <div className="min-h-[80px] flex items-center justify-center">
          <p
            className="text-sm m-6"
            style={{
              fontFamily,
              color: fontColor,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: content }}></span>
          </p>
        </div>
        {logoUrl && (
          <div>
            <InteractivityLogo url={logoUrl} mb="mb-0" />
          </div>
        )}
      </div>
    </div>
  );
}

import { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useUpdateEffect } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";
import { decompress } from "compress-json";
import { useWebSocketEventSubscribe } from "../utils/events";
import { useWebSocket } from "../utils/use_websocket";
import { fetchWithAuth } from "../utils/fetchutils";
import { useTakoContext } from "../tako_context";
import {
  Instance,
  Interactivities,
  InteractivityOption,
  SelectOptionParams,
} from "./types";
import {
  instanceIdStore,
  interactivitiesStore,
  interactivityStore,
  selectedAnswerStore,
  isPromptStore,
  isPromptMessageStore,
  isAgreementModalStore,
  isPromptMissedSceneStore,
  isParticipantIdleStore,
  isFirstTimeLoggedInStore,
  sourceStore,
} from "../atoms";

type NoActiveInstance = { message: string };

export function useInstance({ participantId }: { participantId: string }) {
  const { apiUrl } = useTakoContext();
  const { connectionStatus: status, apiUrl: apiEndpoint } = useWebSocket();

  const baseUrl = `${apiUrl}/v2`;
  const queryClient = useQueryClient();
  const hasApiEndpoint = !!apiEndpoint || !!apiUrl;
  const [instanceId, setInstanceId] = useAtom(instanceIdStore);
  const [interactivities, setInteractivities] = useAtom(interactivitiesStore);
  const [selectedAnswer, setSelectedAnswer] = useAtom(selectedAnswerStore);
  const [isPrompt, setIsPrompt] = useAtom(isPromptStore);
  const [promptMessage, setPromptMessage] = useAtom(isPromptMessageStore);
  const [source, setSource] = useAtom(sourceStore);

  const [isPromptMissedScene, setIsPromptMissedScene] = useAtom(
    isPromptMissedSceneStore
  );
  const [isAgreementModal, setIsAgreementModal] = useAtom(
    isAgreementModalStore
  );
  const [isParticipantIdle, setIsParticipantIdle] = useAtom(
    isParticipantIdleStore
  );
  const [isFirstTimeLoggedIn, setIsFirstTimeLoggedIn] = useAtom(
    isFirstTimeLoggedInStore
  );
  const [_i, setInteractivityStore] = useAtom(interactivityStore);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [titlingClose, setTitlingClose] = useState(false);
  const [adsDisplayOpened, setAdsDisplayOpened] = useState(true);
  const [interactivity, setInteractivity] = useState<
    Interactivities[number] | null
  >(null);
  const [interactivityOptions, setInteractivityOptions] = useState<
    InteractivityOption[]
  >([]);

  const previewInteractivity = Object.values(interactivities ?? {}).find(
    (inter) =>
      inter.type === "QUIZ" ||
      inter.type === "PREDICTION" ||
      inter.type === "POLL"
  );
  useEffect(() => {
    if (isPromptMissedScene && timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, [isPromptMissedScene]);

  // used only for preview (unauth participants)
  const { data: instance, refetch: instanceRefetch } = useQuery<
    Omit<Instance, "groupId">
  >({
    queryKey: ["get_active_instance"],
    queryFn: async () => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(`${baseUrl}/instances/active_instance`);

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    enabled: !participantId,
    onSuccess(data) {
      if (!!data?.instanceId) setInstanceId(data.instanceId);
    },
  });

  const {
    refetch: participantInstanceRefetch,
    data: participantInstance,
    isLoading: participantInstanceLoading,
    isRefetching: participantInstanceRefetching,
    fetchStatus: participantInstanceFetchStatus,
    status: participantInstanceStatus,
  } = useQuery<Instance>({
    queryKey: ["get_participant_instancev2"],
    queryFn: async () => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const url = new URL(`${baseUrl}/instances/get_participant_instancev2`);
      url.searchParams.set("participantId", participantId);
      url.searchParams.set("src", source || "KOL");

      const res = await fetchWithAuth(url.toString());

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    enabled: !!participantId && participantId !== "",
  });

  const {
    data: interactivitiesResult,
    isLoading: interactivitiesLoading,
    refetch: refetchInteractivitiesResult,
  } = useQuery<Interactivities | NoActiveInstance>({
    queryKey: ["get_interactivities"],
    queryFn: async () => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(
        `${baseUrl}/playlists/get_active_instance_playlist`
      );

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    enabled: !!instanceId && interactivities === null,
  });

  const { data: selectedOption, isLoading: selectedOptionLoading } = useQuery<{
    selectedOptionId: string;
  }>({
    queryKey: ["get_selected_optionv2"],
    queryFn: async ({ queryKey }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(
        `${baseUrl}/instances/get_selected_optionv2?instanceId=${participantInstance?.instanceId}&participantId=${participantId}`
      );

      return res.json();
    },
    enabled:
      !selectedAnswer &&
      !!participantId &&
      !!participantInstance?.instanceId &&
      !!participantInstance?.activeSceneId,
  });

  const { mutate: selectOption } = useMutation(
    async (input: SelectOptionParams) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(`${baseUrl}/instances/select_optionv2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      });

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    }
  );

  const { mutate: storeAgreementModal } = useMutation(
    async (input: {
      participantId: string;
      playlistId: string;
      instanceId: string;
      agreementModalId: string;
      participantAction: string;
      ngroupId: string;
    }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(`${baseUrl}/agreement_modal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      });

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    }
  );

  const handleStoreAgreement = async (participantAction: string) => {
    try {
      const metadata = JSON.parse(interactivity?.metadata ?? "{}");
      const agreementGroup = metadata?.agreementGroup;
      let nextGroup = "";
      if (agreementGroup && participantAction in agreementGroup) {
        nextGroup = agreementGroup[participantAction];
      } else {
        console.warn("Participant action not found in agreement group");
        return;
      }
      const agreementModalData = {
        participantId,
        playlistId: participantInstance?.playlistId ?? "",
        instanceId,
        agreementModalId: metadata?.agreementModalId ?? "",
        ngroupId: nextGroup,
        currentGroup: participantInstance?.groupId ?? "",
        participantAction: participantAction,
        interactivityId: interactivity?.interactivityId ?? "",
        sceneId: participantInstance?.activeSceneId ?? "",
      };
      // setPromptMessage(
      //   participantAction === "AGREED"
      //     ? "Congrats Katropie! Kasali ka sa raffle!"
      //     : "Aww sayang! Hindi ka makakasali sa raffle."
      // );
      setIsParticipantIdle(false);
      // setIsPrompt(true);
      timeoutIdRef.current = setTimeout(() => {
        setIsPrompt(false);
      }, 10000);
      storeAgreementModal(agreementModalData);
      setIsAgreementModal(false);
    } catch (error) {
      console.error("Error in [use_instance] handleStoreAgreement:", error);
    }
  };

  const { mutate: createAdMetric } = useMutation(
    async (input: {
      metric: string;
      participantId: string;
      playlistId: string;
      instanceId: string;
      adsId: string;
    }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(`${baseUrl}/ads/create_ads_metric`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      });

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    }
  );

  const selectOptionMutate = (params: SelectOptionParams) => {
    console.log("[tako][inter][use] => SETTING new answser");
    setIsPromptMissedScene(false);
    setIsParticipantIdle(false);
    const prevOption = selectedAnswer;
    setSelectedAnswer(params.metadata.optionId);

    selectOption(params, {
      onError() {
        setSelectedAnswer(prevOption);
      },
    });
  };

  useEffect(() => {
    if (participantId && participantInstance?.activeSceneId) {
      setIsFirstTimeLoggedIn(true);
      console.log("[tako][inter][use] participantId", participantId);
    }
  }, [participantId]);

  const getInteractivityBySceneGroupId = (eventGroupId?: string) => {
    if (!interactivities || !participantInstance?.activeSceneId) return;
    console.log(
      "[tako][inter][use] => RUNNING INTERACTIVITY for sceneId ",
      participantInstance?.activeSceneId
    );

    const groupId = eventGroupId ?? participantInstance?.groupId ?? "";
    const key = participantInstance.activeSceneId + "_" + groupId;
    const result = interactivities[key];
    if (result && isFirstTimeLoggedIn) {
      setIsFirstTimeLoggedIn(false);
    }
    if (!result && isFirstTimeLoggedIn && participantId) {
      setIsFirstTimeLoggedIn(false);
      handleShowPrompt(
        "Aww, sayang!! Hindi ka nakasali Katropie, bawi next time!"
      );
    }

    // Add as impression if type "ADS"
    if (result?.type === "ADS") {
      createAdMetric({
        metric: "IMPRESSION",
        participantId,
        playlistId: participantInstance?.playlistId ?? "",
        instanceId: participantInstance?.instanceId ?? "",
        adsId: JSON.parse(result?.metadata ?? "{}")?.adsId ?? "",
      });
    }

    setInteractivityOptions(result?.options ?? []);
    setInteractivity(result ?? null);
  };

  const reset = () => {
    setTitlingClose(false);
    setIsAgreementModal(false);
    setSelectedAnswer(null);
    setSource(""); // Reset source to capture accurate user data
    setInstanceId("");

    queryClient.setQueryData<NoActiveInstance>(
      ["get_participant_instancev2"],
      () => ({
        message: "No active instance!",
      })
    );
    queryClient.setQueryData<NoActiveInstance>(["get_interactivities"], () => ({
      message: "No active instance!",
    }));
    queryClient.setQueryData<NoActiveInstance>(["get_active_instance"], () => ({
      message: "No active instance!",
    }));
  };

  const isNotInteractivities = (
    interactivitiesResult: Interactivities | NoActiveInstance
  ): interactivitiesResult is NoActiveInstance =>
    "message" in interactivitiesResult;

  const invalidateGetInteractivities = () => {
    setInteractivities(null);
    queryClient.invalidateQueries({
      queryKey: ["get_interactivities"],
    });
  };

  // playlist started
  useWebSocketEventSubscribe("initiated_instance", uuidv4(), (payload) => {
    const { data } =
      (payload as {
        data: {
          instanceId: string;
          playlistId: string;
          groupId: string;
          interactivities: Interactivities;
        };
      }) ?? {};

    console.log(
      `[tako][inter][use] => (initiated_instance) updating active instance Id with ${data.instanceId} and group id ${data.groupId} ...`
    );
    console.log(`[tako][inter][use] => (initiated_instance) data.`, data);
    setTitlingClose(false);
    setIsParticipantIdle(true);
    setSelectedAnswer(null);
    setInstanceId(data.instanceId);
    setInteractivities(data.interactivities);
    participantInstanceRefetch();
    instanceRefetch();
    queryClient.setQueryData<Interactivities>(["get_interactivities"], () => {
      return data.interactivities;
    });
    queryClient.setQueryData<Instance>(["get_selected_optionv2"], () => {
      return undefined;
    });
  });

  const handleShowPrompt = (message: string) => {
    setPromptMessage(message);
    setIsPrompt(true);

    if (!isPromptMissedScene) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        setIsPrompt(false);
      }, 10000);
    }
  };

  useWebSocketEventSubscribe(
    "prompt",
    uuidv4(),
    (payload) => {
      const data = payload as { message: string };
      console.log(`[tako][inter][use] => (prompt)`, payload);
      setTitlingClose(true);
      setIsAgreementModal(true);
      handleShowPrompt(data.message);
    },
    [isPrompt]
  );

  // scene started/ended/switched
  useWebSocketEventSubscribe(
    "broadcasted_scene",
    uuidv4(),
    (payload) => {
      const { data } =
        (decompress(payload as any) as {
          data: {
            instanceId: string;
            activeSceneId: string | null;
            showDisplay: boolean;
            broadCastType: "switch" | "start" | "end";
            runId: string | null;
          };
        }) ?? {};
      console.log(
        `[tako][inter][use] => (broadcasted_scene) updating active scene Id with ${data.activeSceneId} ...`
      );
      setTitlingClose(true);
      setAdsDisplayOpened(true);
      setIsAgreementModal(true);

      if (
        (data.broadCastType === "switch" || data.broadCastType === "end") &&
        isParticipantIdle
      ) {
        // DO NOT REMOVE TEMPORARY DISABLED.
        // handleShowPrompt(
        //   "Aww, sayang!! Hindi ka nakasali Katropie, bawi next time!"
        // );
        setIsParticipantIdle(false);
      }
      setTitlingClose(false);

      // if there's no list of interactivities, refetch
      if (!interactivities) {
        refetchInteractivitiesResult();
      }

      queryClient.setQueryData<Omit<Instance, "groupId">>(
        ["get_active_instance"],
        (updater) => {
          if (!!updater)
            return {
              instanceId: data.instanceId,
              playlistId: updater.playlistId,
              activeSceneId: data.activeSceneId,
              showDisplay: data.showDisplay,
              runId: data.runId,
            };

          return updater;
        }
      );

      // update cache participant instance (temporary remove)
      // queryClient.setQueryData<Instance>(
      //   ["get_participant_instancev2"],
      //   (updater) => {
      //     if (!!updater)
      //       return {
      //         instanceId: data.instanceId,
      //         playlistId: updater.playlistId,
      //         activeSceneId: data.activeSceneId,
      //         showDisplay: data.showDisplay,
      //         runId: data.runId,
      //         groupId: updater.groupId,
      //       };

      //     return updater;
      //   }
      // );

      participantInstanceRefetch();

      // reset user selected answer
      setSelectedAnswer(null);
      queryClient.setQueryData<Instance>(["get_selected_optionv2"], () => {
        return undefined;
      });
    },
    [participantId, selectedAnswer]
  );

  useWebSocketEventSubscribe(
    "toggle_interactivity_display",
    uuidv4(),
    (payload) => {
      const { data } = payload as { data: { showDisplay: boolean } };
      console.log(
        "[tako][inter][use] => (toggle_interactivity_display),",
        data.showDisplay
      );

      // update cache participant instance
      queryClient.setQueryData<Instance>(
        ["get_participant_instancev2"],
        (updater) => {
          if (!!updater)
            return {
              ...updater,
              showDisplay: data.showDisplay,
            };

          return updater;
        }
      );

      queryClient.setQueryData<Omit<Instance, "groupId">>(
        ["get_active_instance"],
        (updater) => {
          if (!!updater)
            return {
              ...updater,
              showDisplay: data.showDisplay,
            };

          return updater;
        }
      );
    }
  );

  // event listening for lock interactivity
  useWebSocketEventSubscribe("toggled_interactivity", uuidv4(), (payload) => {
    const { data } = payload as {
      data: { interactivityId?: string; sceneId?: string; isLocked: boolean };
    };
    console.log(
      "[tako][inter][use] => (toggled_interactivity_lock) ",
      data.isLocked
    );

    // update current interactivity isLocked state
    setInteractivity((curr) => {
      let isLocked = !!curr?.isLocked;

      if (
        !!data.interactivityId &&
        data.interactivityId === curr?.interactivityId
      )
        isLocked = data.isLocked;
      else if (!!data.sceneId) isLocked = data.isLocked;

      const newVal = {
        ...curr,
        isLocked,
      } as typeof interactivity;
      return newVal;
    });
  });

  useWebSocketEventSubscribe("ended_activity", uuidv4(), () => {
    console.log(
      "[tako][inter][use] => (ended_activity) resetting states and queries ..."
    );
    reset();
  });

  useWebSocketEventSubscribe("ended_instance", uuidv4(), () => {
    console.log(
      "[tako][inter][use] => (ended_instance) resetting states and queries ..."
    );
    reset();
  });

  useUpdateEffect(() => {
    // check if users instance is still updated before invalidating (refresh)
    if (
      !!participantInstance?.instanceId &&
      participantInstance.instanceId !== instanceId &&
      !participantInstanceLoading &&
      !participantInstanceRefetching
    ) {
      console.log(
        "[tako][inter][use] => SYNCING get_interactivities and instanceId ..."
      );
      invalidateGetInteractivities();
      queryClient.setQueryData<Instance>(["get_selected_optionv2"], () => {
        return undefined;
      });
      setSelectedAnswer(null);
      setInstanceId(participantInstance?.instanceId ?? "");
    }

    // if no interactivities exist in current active instance (refresh)
    if (
      !!participantInstance?.instanceId &&
      participantInstance.instanceId === instanceId &&
      !interactivitiesLoading &&
      (!interactivitiesResult || !!interactivitiesResult?.message)
    ) {
      console.log("[tako][inter][use] => SYNCING get_interactivities ...");
      invalidateGetInteractivities();
    }

    if (
      !participantInstance?.instanceId &&
      !participantInstanceLoading &&
      !participantInstanceRefetching &&
      !!instanceId &&
      !!interactivities
    ) {
      console.log("[tako][inter][use] => RESET CACHE ...");
      setInstanceId("");
      setInteractivities(null);
    }
  }, [
    participantInstance,
    participantInstanceLoading,
    participantInstanceRefetching,
    instanceId,
  ]);

  // run interactivity if active scene exist
  useUpdateEffect(() => {
    if (
      status === "connected" &&
      participantInstanceFetchStatus === "idle" &&
      participantInstanceStatus === "success" &&
      !!interactivities &&
      !!participantInstance?.activeSceneId
    ) {
      getInteractivityBySceneGroupId();
      return;
    }

    setInteractivityOptions([]);
    setInteractivity(null);
  }, [
    participantInstance,
    participantInstanceFetchStatus,
    participantInstanceStatus,
    interactivities,
    status,
  ]);

  // update atom state for interactivities
  useUpdateEffect(() => {
    if (!!interactivitiesResult && !interactivitiesLoading)
      if (isNotInteractivities(interactivitiesResult)) setInteractivities(null);
      else setInteractivities(interactivitiesResult);
  }, [interactivitiesResult, interactivitiesLoading]);

  // get selected answer from api call
  useUpdateEffect(() => {
    if (!selectedOptionLoading)
      setSelectedAnswer(selectedOption?.selectedOptionId ?? null);
  }, [selectedOption, selectedOptionLoading]);

  useUpdateEffect(() => {
    setInteractivityStore({
      interactivityId: interactivity?.interactivityId ?? "",
      type: interactivity?.type ?? "",
      sceneId: participantInstance?.activeSceneId ?? "",
    });
  }, [interactivity]);

  const closeIsPrompt = () => {
    setIsPrompt(false);
    setIsPromptMissedScene(false);
  };
  return {
    participantInstance,
    interactivity,
    instance,
    previewInteractivity,
    interactivityOptions,
    participantInstanceLoading,
    selectOptionMutate,
    selectedAnswer: selectedAnswer,
    titlingClose,
    isPrompt: isPrompt,
    promptMessage,
    isAgreementModal,
    handleStoreAgreement,
    closeIsPrompt,
    isPromptMissedScene,
    setIsPromptMissedScene,
    handleTitlingClose: () => {
      setTitlingClose((prevState) => !prevState);
    },
    adsDisplayOpened,
    setAdsDisplayOpened,
  };
}

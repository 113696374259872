import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler } from "@yornaath/batshit";
import { useTakoContext } from "../tako_context";
import { fetchWithAuth } from "../utils/fetchutils";
import { CACHE_TIME, STALE_TIME } from "../constants";

type FetchParticipantReturn = {
  participantId: string;
  data?: { firstName: string; lastName: string; fullName: string };
}[];
type FetchParticipantPayload = { participantId: string; baseUrl: string };

const participant = create<FetchParticipantReturn, FetchParticipantPayload>({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (payloads) => {
    try {
      const baseUrl = payloads[0].baseUrl;
      const participantIds = payloads.map((payload) => payload.participantId);
      const joinedParticipantIds = participantIds.join(",");
      const res = await fetchWithAuth(
        `${baseUrl}/v2/participants/batch?participant_ids=${joinedParticipantIds}`
      );
      const data = (await res.json()) as {
        participantId: string;
        firstName: string;
        lastName: string;
        fullName: string;
      }[];

      const mapped = participantIds.map((participantId) => ({
        participantId: participantId,
        data: data.find(
          (datum: { participantId: string }) =>
            datum.participantId === participantId
        ),
      }));
      console.log({ mapped, participantIds });

      return mapped;
    } catch (err) {
      throw new Error("Something went wrong");
    }
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: (participants, query) => {
    const filtered = participants.filter(
      (participant) => participant.participantId === query.participantId
    );

    return filtered;
  },
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

export const generateBatchGetParticipantByIdKey = (participantId: string) => [
  "participants",
  "participant",
  participantId,
];

export function useBatchGetParticipantById(participantId: string) {
  const { apiUrl } = useTakoContext();
  return useQuery(
    generateBatchGetParticipantByIdKey(participantId),
    async () => {
      const result = await participant.fetch({
        participantId,
        baseUrl: apiUrl,
      });

      return result[0];
    },
    {
      enabled: !!participantId,
    }
  );
}

export function useGetParticipantById(args: {
  participantId: string;
  onSuccess?: (data: {
    firstName: string;
    lastName: string;
    status: string;
    pausedDuration: string;
    avatarImgUrl: string | null;
    isFirstTimeUser: boolean;
  }) => void;
}) {
  const { apiUrl } = useTakoContext();

  return useQuery<{
    firstName: string;
    lastName: string;
    status: string;
    pausedDuration: string;
    avatarImgUrl: string | null;
    isFirstTimeUser: boolean;
  }>({
    queryKey: ["user", args.participantId],
    queryFn: async ({ queryKey }) => {
      if (!apiUrl) throw new Error("API ENDPOINT UNDEFINED");

      const url = `${apiUrl}/api/v1/participants/${queryKey[1]}`;

      const res = await fetchWithAuth(url);

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    enabled: !!args.participantId,
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
    onSuccess: args?.onSuccess,
  });
}

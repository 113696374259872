import { memo, useMemo } from "react";

import Like from "../assets/reactions/thumbsup.png";
import Angry from "../assets/reactions/angry.png";
import Heart from "../assets/reactions/heart.png";
import Funny from "../assets/reactions/funny.png";
import Sad from "../assets/reactions/sad.png";
import Downvote from "../assets/reactions/thumbsdown.png";
import { Reactions } from "./reactions_types";
import { useBatchGetReactionByMessageId } from "../queries/reactions";

type ReactionTypes = Reactions | string;

function Reaction(props: {
  type: ReactionTypes;
  count: number;
  currUserReaction?: boolean;
  clickable?: boolean;
  messageId?: string;
  disabled?: boolean;
}) {
  const mapping: Record<ReactionTypes, string> = {
    like: Like,
    angry: Angry,
    heart: Heart,
    funny: Funny,
    sad: Sad,
    downvote: Downvote,
  };

  return (
    <div
      className={`${
        props.currUserReaction ? "bg-[#EAAC214D]" : "bg-[#E7E7E8]"
      } flex max-h-6 min-w-10 px-4 py-1 items-center rounded-xl gap-3 cursor:po`}
      // onClick={handleDelete}
    >
      <img src={mapping[props.type]} className="max-h-4 min-w-4" />
      <p className="text-[#373A40] text-sm">{props.count}</p>
    </div>
  );
}

export function MessageReactions({
  messageId,
  currUserReaction,
  disabled,
}: {
  currUserReaction?: {
    reaction: string;
    reactionId: string;
  };
  messageId: string;
  loggedInUserId?: string;
  disabled: boolean;
}) {
  const { data = { reactions: [] }, isLoading } =
    useBatchGetReactionByMessageId(messageId);

  const likes = useMemo(() => {
    return data.reactions.filter(
      (reaction) => reaction.reaction === Reactions.like
    ).length;
  }, [data]);

  const hearts = useMemo(() => {
    return data.reactions.filter(
      (reaction) => reaction.reaction === Reactions.heart
    ).length;
  }, [data]);

  const funny = useMemo(() => {
    return data.reactions.filter(
      (reaction) => reaction.reaction === Reactions.funny
    ).length;
  }, [data]);

  const sad = useMemo(() => {
    return data.reactions.filter(
      (reaction) => reaction.reaction === Reactions.sad
    ).length;
  }, [data]);

  const angry = useMemo(() => {
    return data.reactions.filter(
      (reaction) => reaction.reaction === Reactions.angry
    ).length;
  }, [data]);

  const downvote = useMemo(() => {
    return data.reactions.filter(
      (reaction) => reaction.reaction === Reactions.downvote
    ).length;
  }, [data]);

  let currReactionCount = 0;

  switch (currUserReaction?.reaction) {
    case Reactions.like:
      currReactionCount = likes;
      break;

    case Reactions.heart:
      currReactionCount = hearts;
      break;

    case Reactions.funny:
      currReactionCount = funny;
      break;

    case Reactions.sad:
      currReactionCount = sad;
      break;

    case Reactions.angry:
      currReactionCount = angry;
      break;

    case Reactions.downvote:
      currReactionCount = downvote;
      break;

    default:
      currReactionCount = 0;
      break;
  }

  if (isLoading) return <>loading...</>;

  if (!data) return null;

  // console.log({ currUserReaction });

  return (
    <div className="flex gap-4 flex-wrap mt-2">
      {!!likes && currUserReaction?.reaction !== Reactions.like && (
        <Reaction type={Reactions.like} count={likes} disabled={disabled} />
      )}
      {!!hearts && currUserReaction?.reaction !== Reactions.heart && (
        <Reaction type={Reactions.heart} count={hearts} disabled={disabled} />
      )}
      {!!funny && currUserReaction?.reaction !== Reactions.funny && (
        <Reaction type={Reactions.funny} count={funny} disabled={disabled} />
      )}
      {!!angry && currUserReaction?.reaction !== Reactions.angry && (
        <Reaction type={Reactions.angry} count={angry} disabled={disabled} />
      )}
      {!!sad && currUserReaction?.reaction !== Reactions.sad && (
        <Reaction type={Reactions.sad} count={sad} disabled={disabled} />
      )}
      {!!downvote && currUserReaction?.reaction !== Reactions.downvote && (
        <Reaction
          type={Reactions.downvote}
          count={downvote}
          disabled={disabled}
        />
      )}
      {currUserReaction && (
        <div className="flex gap-2">
          <Reaction
            type={currUserReaction.reaction}
            count={currReactionCount}
            currUserReaction
            messageId={messageId}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
}

export default memo(MessageReactions);

import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useChatContext } from "./chat_provider";

const RULES = [
  "Be kind.",
  "Be respectful.",
  "Listen to your moderators.",
  "No bullying, sexism, racism, homophobia or other hate-based chat.",
  "No ethnic, sexual, religious, disability, agist or transphobic slurs.",
  "Don't spam words or use all-caps.",
  "No spoilers to a game, TV show or film.",
  "Don't argue with people over chat —— especially the moderators.",
  "Use the language(s) spoken by the broadcaster (so they can reply to what you are saying and other people in chat can understand the conversation).",
  "No advertising or self-promotion.",
  "Chat with humor.",
];

function ChatRules() {
  const [opened, setOpened] = useState(true);
  const { activateParticipant, getParticipantQuery } = useChatContext();
  const isFirstTimeUser = getParticipantQuery.data?.isFirstTimeUser;

  if (!isFirstTimeUser || !opened) return null;

  const content = (
    <>
      <div className="w-full min-h-[53px] rounded-t-xl bg-[#CD3A13] flex items-center justify-center">
        <h3 className="text-base font-semibold text-white text-center">
          Chat Rules
        </h3>
      </div>
      <div className="w-full border-2 border-[#AAAAAA] border-t-0">
        <div className="w-full p-4">
          <ol className="flex flex-col gap-2 text-black">
            {RULES.map((rule, index) => (
              <li key={index}>{`${index + 1}. ${rule}`}</li>
            ))}
          </ol>
        </div>
        <div className="w-full p-4">
          <p className="text-center font-semibold text-sm text-black">
            HAVE FUN AND HAVE A LAUGH
          </p>
          {isFirstTimeUser ? (
            <button
              className="w-full h-[35px] mt-4 hover:bg-[#E44116] bg-[#E44116] active:scale-[.995] text-white text-sm"
              onClick={() => activateParticipant()}
            >
              Okay, Got it!
            </button>
          ) : (
            <button
              className="w-full h-[35px] mt-4 hover:bg-[#E44116] bg-[#E44116] active:scale-[.995] text-white text-sm"
              onClick={() => setOpened(false)}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </>
  );

  return (
    <AnimatePresence>
      <motion.div
        className="z-[11] p-4 w-full mt-auto bg-[#EEEEEE]"
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "-100vh" }}
        transition={{ type: "spring" }}
      >
        {content}
      </motion.div>
    </AnimatePresence>
  );
}

export default ChatRules;

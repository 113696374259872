export interface AWSCognitoCredentialOptions {
  IdentityPoolId: string;
  Region: string;
}

export interface AWSCredentials {
  aws_region: string;
  aws_access_id: string;
  aws_secret_key: string;
  aws_sts_token?: string;
  aws_identity_id?: string;
  expiration?: string; // ISO 8901 timestamp
}

export type DeviceCallback = () => void;
export type DeviceMessageCallback = (topic: string, payload: any) => void;
export type DeviceErrorCallback = (error: Error | string) => void;

export type DeviceConnectResponse = {
  clientId: string;
  expiration: string; // ISO 8901 timestamp
};

export type DeviceSubscriptionGrant = {
  topic: string;
  qos: number;
};

export type DeviceSubscribeResponse = {
  grants: DeviceSubscriptionGrant[];
  subscriptionId: string;
};

export type DeviceUnsubscribeResponse = {
  packet: any;
};

export type DeviceSetIdentityResponse = {
  clientId: string;
};

export type DeviceUnsetIdentityResponse = {
  clientId: string;
};

export interface IIotDevice {
  setOnConnect: (listener: DeviceCallback) => void;
  setOnClose: (listener: DeviceCallback) => void;
  setOnReconnect: (listener: DeviceCallback) => void;
  setOnOffline: (listener: DeviceCallback) => void;
  setOnError: (listener: DeviceErrorCallback) => void;
  setOnMessage: (listener: DeviceMessageCallback) => void;

  connect: (
    identityProvider?: string,
    token?: string
  ) => Promise<DeviceConnectResponse>;
  subscribe: (topics: string[]) => Promise<DeviceSubscribeResponse>;
  unsubscribe: (topics: string[]) => Promise<DeviceUnsubscribeResponse>;
  publish: (topic: string, message: string) => Promise<void>;

  setIdentity: (
    idp: string,
    token: string
  ) => Promise<DeviceSetIdentityResponse>;

  unsetIdentity: () => Promise<DeviceUnsetIdentityResponse>;

  disconnect: () => Promise<void>;

  isConnected: () => boolean;
}
/**
 *
 * IotDevice.subscribe('chatStored', (payload: string) => {})
 * IoTDevice.unsubscribe([''])
 *
 */

export type GetCredentialsApiResponse = {
  connectionId: string;
  credentials: {
    identityId: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
    expiration?: string; // ISO 8901 timestamp
  };
};

export type CredsObject = {
  aws_region: string;
  aws_access_id: string;
  aws_secret_key: string;
  aws_sts_token: string;
  aws_identity_id: string;
  expiration: string; // ISO 8901 timestamp
  connectionId: string;
};

export type SubscribeFunctionCb = (payload: unknown) => void;
export type SubscribeFunctionErrorCb = (error: Error) => void;

export type SubscribeAsyncFunctionCb = (payload: unknown) => Promise<void>;
export type SubscribeAsyncFunctionErrorCb = (error: Error) => Promise<void>;

export const postJson = async <T, V>(
  url: string,
  body: T,
  headers?: Record<string, string>
): Promise<V | null> => {
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(headers ?? {}),
    },
    body: JSON.stringify(body),
  });

  const rtype = resp.headers.get("Content-Type");

  if (!rtype) {
    return null;
  }

  if (rtype.startsWith("application/json") && resp.ok) {
    const j: V = (await resp.json()) as V;
    return j;
  }

  const err = await resp.text();
  console.error(`postJson(): ${err}`);
  throw new Error(err);
};

export const toBase64 = (uint8Buffer: Uint8Array) => {
  const base64 = btoa(
    uint8Buffer.reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "")
  );

  return base64;
};

export type SubscribeFunctionCbPromise = (data: any) => Promise<void>;

export type WebSocketPublishFuncType = (
  topic: string,
  message: unknown,
  correlationId?: string
) => Promise<void>;

export type WebSocketSubscribeFuncResultType = {
  success: string;
  topic: string;
  result?: any;
  message?: string;
};

export type WebSocketSubscribeFuncType = (
  topic: string,
  cb: SubscribeFunctionCbPromise,
  correlationId?: string,
  wso?: any
) => Promise<WebSocketSubscribeFuncResultType>;

export type WebSocketOnConnectHandler = (webSocket: any) => Promise<void>;

export interface IWebSocketAdapter {
  publish: WebSocketPublishFuncType;
  subscribe: WebSocketSubscribeFuncType;
  onConnect?: WebSocketOnConnectHandler;
}

import { useQuery } from "@tanstack/react-query";
import { useTakoContext } from "../tako_context";
import { Instance } from "./types";
import { useWebSocket } from "../utils/use_websocket";
import { useFeatureFlag } from "../utils/feature_flag";
import { fetchWithAuth } from "../utils/fetchutils";
import { useGetVoucherFontSize } from "./use_get_voucher_font_size";

type InteractivityVoucherDisplayProps = {
  voucherId: string;
  participantId: string;
  instance?: Omit<Instance, "groupId">;
  backgroundColor: string;
};

export default function InteractivityVoucherDisplay(
  props: InteractivityVoucherDisplayProps
) {
  const { implementation, apiUrl: apiEndpoint } = useWebSocket();

  console.log(
    "interactivity_voucher_display.tsx: InteractivityVoucherDisplay: ",
    {
      implementation,
      apiEndpoint,
    }
  );

  const { apiUrl } = useTakoContext();
  const { hasV2EndpointsEnabled } = useFeatureFlag();
  const baseUrl = hasV2EndpointsEnabled
    ? `${apiUrl}/v2`
    : `${apiEndpoint}/api/v1`;
  const hasApiEndpoint = !!apiEndpoint || !!apiUrl;

  const { data: voucher } = useQuery({
    queryKey: ["getVoucherById", props?.voucherId ?? ""],
    queryFn: async ({ queryKey }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(`${baseUrl}/vouchers/${queryKey[1]}`);

      return res.json();
    },
  });

  const { appearance, content } = voucher ?? { appearance: {}, content: {} };

  const { desktopFonts, mobileFonts } = useGetVoucherFontSize(
    appearance?.fontSize ?? "33.3"
  );

  const fontFamily = appearance?.fontFamily ?? "Poppins";

  const getBackgroundColor = () => {
    if (
      !!appearance?.bgColor &&
      !!appearance.isBgGradient &&
      !!appearance.secondaryBgColor
    ) {
      return {
        backgroundImage: `linear-gradient(90deg, ${appearance.bgColor} 35%, ${appearance.secondaryBgColor} 100%)`,
      };
    }

    return {
      backgroundColor: appearance?.bgColor ?? "#909296",
    };
  };

  const redirectUrl = content?.ctaUrl?.includes("http")
    ? content?.ctaUrl
    : `//${content?.ctaUrl}`;

  return (
    <div className="p-4 m-4 rounded" style={getBackgroundColor()}>
      <div className="flex justify-between w-full relative">
        <div className="flex-col break-normal">
          <h2
            style={{
              color: appearance?.fontColor ?? "#FFF",
              fontFamily,
              fontSize: desktopFonts.headerFontSize,
            }}
          >
            {content?.header ?? ""}
          </h2>
          <span
            style={{
              color: appearance?.fontColor ?? "#FFF",
              fontFamily,
              fontSize: desktopFonts.subHeaderFontSize,
            }}
          >
            {content?.subHeader ?? ""}
          </span>
        </div>
        <h2
          style={{
            color: appearance?.fontColor ?? "#FFF",
            fontFamily,
            fontSize: desktopFonts.promoFontSize,
          }}
        >
          {content?.promo ?? ""}
        </h2>
        <div
          style={{
            backgroundColor: props.backgroundColor ?? "#F9ECCE",
          }}
          className="w-6 h-6 absolute bottom-0 left-0 rounded-full translate-x-[-24px] translate-y-[24px]"
        />
        <div className="border-dashed border-b-2 absolute bottom-0 left-0 right-0 translate-y-[16px] border-white" />
        <div
          style={{
            backgroundColor: props.backgroundColor ?? "#F9ECCE",
          }}
          className="w-6 h-6 absolute bottom-0 right-0 rounded-full translate-x-[24px] translate-y-[24px]"
        />
      </div>

      <div className="flex justify-between w-full relative mt-8">
        <div className="flex w-full items-center gap-5">
          <span
            style={{
              color: appearance?.fontColor ?? "#FFF",
              fontFamily,
              fontSize: desktopFonts.validityFontSize,
            }}
          >
            Validity:
          </span>
          <span
            style={{
              color: appearance?.fontColor ?? "#FFF",
              fontFamily,
              fontSize: desktopFonts.validityFontSize,
            }}
          >
            {content?.validity ?? ""}
          </span>
        </div>

        <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
          <button
            className="rounded-3xl min-w-[100px] min-h-[48px]"
            style={{
              backgroundColor: appearance?.fontColor ?? "#FFF",
            }}
          >
            <span
              style={{
                color: appearance?.bgColor ?? "#FFF",
                fontFamily,
                fontSize: desktopFonts.buttonFontSize,
              }}
            >
              {content?.ctaLabel ?? ""}
            </span>
          </button>
        </a>
      </div>
    </div>
  );
}

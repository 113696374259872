import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { TbAlertTriangleFilled } from "react-icons/tb";

function Fallback({
  resetErrorBoundary,
  error,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  console.log("error", error);

  return (
    <div className="h-[100%] flex items-center justify-center">
      <div className="bg-[#EAAC2133] bg-opacity-20 h-[180px] w-[80%] mx-auto flex items-center justify-center flex-col px-6 gap-4">
        <div className="flex flex-col gap-2 items-center justify-center">
          <h2 className="text-[#EAAC21] flex gap-4 items-center text-base font-semibold">
            <TbAlertTriangleFilled size={20} />
            Error
          </h2>
          <p className="text-center text-sm font-normal text-[#1A1B1E]">
            Oops! Something went wrong. We're sorry, but we couldn't process
            your request at this time. Please try again later.
          </p>
        </div>

        <button
          className="py-2 px-6 rounded-md bg-[#EAAC21] text-[#212227]"
          onClick={resetErrorBoundary}
        >
          Refresh
        </button>
      </div>
    </div>
  );
}

export default function ErrorHandler({ children }: { children: ReactNode }) {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
}

export const formatUrl = (content: string): string => {
  const urlRegex =
    /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
  const matches = content.matchAll(urlRegex);

  for (const match of matches) {
    const url = match[0];
    const urlWithProtocol =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`;

    content = content.replaceAll(
      url,
      `<a href='${urlWithProtocol}' target='_blank' rel='noopener noreferrer'>${url}</a>`
    );
  }

  return content;
};

export const checkAnchorTag = (message: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(message, "text/html");
  const links = doc.querySelectorAll("a");
  const anchorTags = Array.from(links)
    .map((link) => link.outerHTML)
    .join(",");

  return anchorTags ? anchorTags : message;
};
import { useState, useEffect, useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import TicketImage from "../assets/ticket_image.svg";
import { useTakoContext } from "../tako_context";
import { useThemeContext } from "../utils/themes";
import { useWebSocket } from "../utils/use_websocket";
import { fetchWithAuth } from "../utils/fetchutils";
import { useFeatureFlag } from "../utils/feature_flag";

const useGetAgreementModalFontSize = (size: string, isMobile: boolean) => {
  let headerFontSize = 18;
  let buttonSize = 16;
  let introFontSize = 16;
  let consentFontSize = 16;

  switch (size) {
    case "0": {
      headerFontSize = 16;
      buttonSize = 14;
      introFontSize = 14;
      consentFontSize = 14;
      break;
    }

    case "66": {
      headerFontSize = 20;
      buttonSize = 18;
      introFontSize = 18;
      consentFontSize = 18;
      break;
    }

    case "99": {
      headerFontSize = 22;
      buttonSize = 20;
      introFontSize = 20;
      consentFontSize = 20;
      break;
    }

    default: {
      headerFontSize = 18;
      introFontSize = 16;
      consentFontSize = 16;
      buttonSize = 16;
      break;
    }
  }

  return {
    headerFontSize: headerFontSize - (isMobile ? 2 : 0),
    buttonSize: buttonSize - (isMobile ? 2 : 0),
    introFontSize: introFontSize - (isMobile ? 2 : 0),
    consentFontSize: consentFontSize - (isMobile ? 2 : 0),
  };
};
export default function AgreementDisplay(props: {
  agreementModalId: string;
  isAgreementModal: boolean;
  handleClick: (participantAction: string) => Promise<void>;
}) {
  const [isMobile, setIsMobile] = useState(false);

  const resizeMountedRef = useRef(false);

  useEffect(() => {
    resizeMountedRef.current = true;

    const handleResize = () => {
      if (resizeMountedRef.current) {
        setIsMobile(window.innerWidth <= 768);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      resizeMountedRef.current = false;
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isScale, stIsScale] = useState(false);
  const { implementation, apiUrl: apiEndpoint } = useWebSocket();

  console.log("interactivity_agreement_display.tsx: AgreementDisplay(): ", {
    implementation,
    apiEndpoint,
  });

  const { apiUrl } = useTakoContext();
  const { interactivity, isBentoTheme } = useThemeContext();
  const highlightColor = isBentoTheme
    ? "#AD7800"
    : interactivity?.colors.highlight;
  const fontFamily = interactivity?.font.style || "Poppins";
  const fontColor = isBentoTheme ? "#000" : interactivity?.font.color;

  const hasApiEndpoint = !!apiEndpoint || !!apiUrl;
  const { data, isLoading } = useQuery({
    queryKey: ["agreementModalId", props.agreementModalId],
    queryFn: async ({ queryKey }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(
        `${apiUrl}/v2/agreement_modal/${queryKey[1]}`
      );

      return res.json();
    },
    enabled: !!props.agreementModalId,
  });
  const { hasV2EndpointsEnabled } = useFeatureFlag();

  const { data: logo } = useQuery({
    queryKey: ["get_presigned_url", data?.imgUrl],
    queryFn: async ({ queryKey }) => {
      if (!apiEndpoint) throw new Error("API ENDPOINT UNDEFINED");
      const url = hasV2EndpointsEnabled
        ? `${apiUrl}/v2/images/agreement_pre_signed?imageUrl=${queryKey[1]}`
        : `${apiEndpoint}/api/v1/images/agreement_pre_signed?imageUrl=${queryKey[1]}`;
      const res = await fetchWithAuth(url);
      return res.json();
    },
    enabled: !!data?.imgUrl,
  });

  const { headerFontSize, buttonSize, introFontSize, consentFontSize } =
    useGetAgreementModalFontSize(interactivity.font.size ?? "33.3", isMobile);
  if (isLoading) {
    return <></>;
  }
  const handleAgreementClick = () => {
    if (!isCheckboxChecked) {
      stIsScale(true);
      setTimeout(() => {
        stIsScale(false);
      }, 1000);
    } else {
      props.handleClick("AGREED");
    }
  };
  return props.isAgreementModal ? (
    <div
      className={`m-2 shadow-lg transition-opacity delay-150 ease-in-out`}
      style={{ borderRadius: "8px", borderColor: highlightColor, fontFamily }}
    >
      <div
        className="flex justify-center items-center py-2 uppercase font-semibold"
        style={{
          borderRadius: "8px 8px 0px 0px",
          backgroundColor: highlightColor,
          fontSize: headerFontSize,
        }}
      >
        <span className="text-white" >
          {data?.header === "" ? "[MODAL HEADER]" : data?.header}
        </span>
      </div>

      <div
        className="flex w-full bg-white "
        style={{ borderRadius: "0px 0px 8px 8px" }}
      >
        <div className="flex flex-col gap-4  p-4 w-full justify-between">
          <div className="flex gap-4">
            <div className="w-18">
              <div className="h-[50px] w-[50px]">
                {logo ? (
                  <img src={logo} alt={data?.imgUrl} className="w-auto" />
                ) : (
                  <img src={TicketImage} alt="Ticket" className="w-auto" />
                )}
              </div>
            </div>
            <span
              className="text-black"
              style={{
                fontSize: introFontSize,
              }}
            >
              {data?.introduction === ""
                ? "[INTRODUCTION]"
                : data?.introduction}
            </span>
          </div>
          {data?.type === "CORRECT" && (
            <div>
              {/* Replace the Checkbox with an equivalent Tailwind-styled component */}
              <label
                className={`flex items-center space-x-2 transition ease-in-out delay-150 ${isScale ? "scale-110" : ""
                  } `}
              >
                <input
                  type="checkbox"
                  className={`form-checkbox `}
                  onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  style={{
                    accentColor: highlightColor,
                  }}
                />
                <span
                  className="text-black "
                  style={{
                    fontSize: consentFontSize,
                  }}
                >
                  By clicking submit, I consent to participating in{" "}
                  <span className="font-bold">
                    {data?.program_name === ""
                      ? "[PROGRAM NAME]"
                      : data?.program_name}
                  </span>{" "}
                  (
                  {data?.dti_permit === ""
                    ? "[PERMIT NUMBER]"
                    : data?.dti_permit}
                  )
                </span>
              </label>
            </div>
          )}
          <div className={`flex gap-6 ${isMobile ? "justify-center" : 'justify-end'}  `}>
            <button
              onClick={() => {
                props.handleClick("CANCELED");
              }}
              className=" bg-white text-black border border-black rounded px-6 text-[12px] md:text-[14px] lg:text-[16px] xl-[18px]"
              style={{
                fontSize: buttonSize,
                fontWeight: "400",
              }}
            >
              {data?.cancel_btn ?? "Cancel"}
            </button>

            {data?.type === "CORRECT" && (
              <button
                onClick={() => {
                  handleAgreementClick();
                }}
                className={`font-semibold rounded px-6 text-[12px] md:text-[14px] lg:text-[16px] xl-[18px] ${!isCheckboxChecked ? "cursor-not-allowed" : ""
                  }`}
                style={{
                  backgroundColor: highlightColor,
                  fontSize: buttonSize,
                  fontWeight: "400",
                  padding: "5px 20px 5px 20px"
                }}
              >
                {data?.agree_btn ?? "Agree"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

import Color from "color";
import { useThemeContext } from "../utils/themes";
import { useBatchGetParticipantById } from "../queries/participants";

const NAME_FONT_SIZES = {
  sm: "10px",
  default: "12px",
  md: "14px",
  lg: "16px",
};

export default function MessageUsername({
  participantId,
}: {
  participantId: string;
}) {
  const {
    chat: chatStyle,
    isBentoTheme,
    interactivity: interStyle,
  } = useThemeContext();
  const { data, isLoading } = useBatchGetParticipantById(participantId);
  const chatBackground = chatStyle.colors.background;
  const usernameColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#616E7D"
      : Color(chatBackground).darken(0.5).hex();

  const fontFamily = chatStyle.font.style || "Poppins";
  let username = data?.data?.fullName ?? "Anonymous";

  if (isLoading) username = "...";

  return (
    <span
      className="text-xs leading-none font-bold"
      style={{
        color: usernameColor,
        fontSize:
          NAME_FONT_SIZES[
            chatStyle?.font?.size as keyof typeof NAME_FONT_SIZES
          ] || "12px",
        fontFamily,
      }}
    >
      {username}
    </span>
  );
}

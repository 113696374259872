import { useThemeContext } from "../utils/themes";

type InteractivityWrapperProps = {
  className: string;
  children?: React.ReactNode;
  nativeStyle?: boolean;
  opened?: boolean;
};

export default function InteractivityWrapper({
  className = "",
  children,
  nativeStyle,
  opened = true,
}: InteractivityWrapperProps) {
  const { interactivity: interStyle, isBentoTheme } = useThemeContext();

  if (nativeStyle) {
    return (
      <div
        className={`${className} w-full z-[300]  max-h-full overflow-y-auto text-black`}
      >
        {opened && children}
      </div>
    );
  }

  return (
    <>
      <div
        className={`${className} w-full z-[300] px-4  max-h-full  overflow-y-auto  `}
      >
        {opened && (
          <>
            <div
              className="shadow-xl rounded-t-lg h-[10px]"
              style={{
                backgroundColor: isBentoTheme
                  ? "#AD7800"
                  : interStyle?.colors.highlight,
              }}
            />
            <div
              className="rounded-b-lg relative overflow-hidden z-10 shadow-xl"
              style={{
                backgroundColor: isBentoTheme
                  ? "#EAB53F"
                  : interStyle?.colors.background,
              }}
            >
              {children}
            </div>
          </>
        )}
      </div>
    </>
  );
}

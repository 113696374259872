import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler, keyResolver } from "@yornaath/batshit";
import { useTakoContext } from "../tako_context";
import { fetchWithAuth } from "../utils/fetchutils";

const reaction = create<
  { messageId: string; reactions: { reaction: string }[] }[],
  { messageId: string; baseUrl: string }
>({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (payloads: { messageId: string; baseUrl: string }[]) => {
    try {
      const baseUrl = payloads[0].baseUrl;
      const messageIds = payloads.map((payload) => payload.messageId);
      const messageIdsString = messageIds.join(",");
      // console.log(`${baseUrl}/v2/reactions/batch?msg_ids=${messageIdsString}`);
      const res = await fetchWithAuth(
        `${baseUrl}/v2/reactions/batch?msg_ids=${messageIdsString}`
      );
      const data = await res.json();

      return data;
    } catch (err) {
      console.error("reactions.ts: reaction: ", { err });
      throw new Error("Something went wrong");
    }
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: (messages, query) => {
    const filtered = messages.filter(
      (message) => message.messageId === query.messageId
    );

    return filtered;
  },
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

export const generateBatchGetReactionByMessageIdKey = (messageId: string) => [
  "messages",
  "reactions",
  messageId,
];
export function useBatchGetReactionByMessageId(messageId: string) {
  const { apiUrl } = useTakoContext();
  return useQuery(
    generateBatchGetReactionByMessageIdKey(messageId),
    async () => {
      const result = await reaction.fetch({ messageId, baseUrl: apiUrl });

      return result[0];
    }
  );
}

const currUserReaction = create<
  {
    messageId: string;
    participantId: string;
    reactions: { reaction: string; reactionId: string }[];
  }[],
  { messageId: string; participantId: string; baseUrl: string }
>({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (payloads) => {
    try {
      const baseUrl = payloads[0].baseUrl;
      const messageIds = payloads.map((payload) => payload.messageId);
      const messageIdsString = messageIds.join(",");
      // console.log(
      //   `${baseUrl}/v2/reactions/my_reaction/${payloads[0].participantId}/batch?msg_ids=${messageIdsString}`
      // );
      const res = await fetchWithAuth(
        `${baseUrl}/v2/reactions/my_reaction/${payloads[0].participantId}/batch?msg_ids=${messageIdsString}`
      );
      const data = await res.json();

      return data;
    } catch (err) {
      console.error("reactions.ts: currUserReaction: ", { err });
      throw new Error("Something went wrong");
    }
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: (messages, query) => {
    const filtered = messages.filter(
      (message) =>
        message.messageId === query.messageId &&
        message.participantId === query.participantId
    );

    return filtered;
  },
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

export const generateBatchGetCurrUserReactionByMessageIdKey = (
  messageId: string,
  participantId: string
) => ["messages", "reactions", messageId, participantId];
export function useBatchGetCurrUserReactionByMessageId(
  messageId: string,
  participantId: string
) {
  const { apiUrl } = useTakoContext();
  return useQuery(
    generateBatchGetCurrUserReactionByMessageIdKey(messageId, participantId),
    async () => {
      const result = await currUserReaction.fetch({
        messageId,
        participantId,
        baseUrl: apiUrl,
      });

      return result[0];
    },
    {
      enabled: !!participantId,
    }
  );
}

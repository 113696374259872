import { useQuery } from "@tanstack/react-query";
import { useTakoContext } from "../tako_context";
import { fetchWithAuth } from "../utils/fetchutils";
import { create, windowScheduler } from "@yornaath/batshit";
import { CACHE_TIME, STALE_TIME } from "../constants";
import { GetMessagesByChannelId } from "../chat/types";

type FetchMessageReturn = {
  messageId: string;
  data: {
    messageId: string;
    content: string;
    userId: string;
    createdAt: number;
    isCensored: boolean;
  };
}[];
type FetchMessagePayload = { messageId: string; baseUrl: string };

export const batchMessage = create<FetchMessageReturn, FetchMessagePayload>({
  fetcher: async (payloads) => {
    const baseUrl = payloads[0].baseUrl;
    const messageIds = payloads.map((payload) => payload.messageId);
    const joined = messageIds.join(",");
    const res = await fetchWithAuth(
      `${baseUrl}/v3/messages/batch?msg_ids=${joined}`
    );
    const data = (await res.json()) as {
      messageId: string;
      content: string;
      userId: string;
      createdAt: number;
      isCensored: boolean;
    }[];

    console.log({ data, payloads });

    const mapped = messageIds.map((messageId) => ({
      messageId,
      data: data.find((datum) => datum.messageId === messageId) ?? {
        content: "",
        messageId,
        userId: "",
        createdAt: 0,
        isCensored: false,
      },
    }));

    return mapped;
  },
  resolver: (messages, query) => {
    return messages.filter((message) => message.messageId === query.messageId);
  },
  scheduler: windowScheduler(10),
});

export const generateBatchGetParentMessageKey = (messageId: string) => [
  "messages",
  "parent",
  messageId,
];
export function useBatchGetParentMessage(messageId: string) {
  const { apiUrl } = useTakoContext();
  return useQuery(
    generateBatchGetParentMessageKey(messageId),
    async () => {
      const res = await batchMessage.fetch({ messageId, baseUrl: apiUrl });

      return res[0];
    },
    {
      enabled: !!messageId,
    }
  );
}

export const useGetMessages = (args?: {
  onSuccess?: (data: GetMessagesByChannelId) => void;
}) => {
  const { apiUrl } = useTakoContext();

  return useQuery<GetMessagesByChannelId>(
    ["channel", "global_chat_channel"],
    async ({ queryKey }) => {
      if (!apiUrl) throw new Error("API ENDPOINT UNDEFINED");

      const url = `${apiUrl}/v3/messages/channel/${queryKey[1]}`;

      const res = await fetchWithAuth(url);

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    {
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      onSuccess: args?.onSuccess,
      refetchOnMount: "always",
    }
  );
};

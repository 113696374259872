const wssUrl = {
  development: "wss://tako-dev-iot.kromaprodtech.com/mqtt",
  staging: "wss://tako-staging-iot.kromaprodtech.com/mqtt",
  production: "wss://tako-iot.kromaprodtech.com/mqtt",
};

const apiUrl = {
  development: "https://compsvc.dev.tako.kromaprodtech.com",
  staging: "https://compsvc.staging.tako.kromaprodtech.com",
  production: "https://compsvc.tako.kromaprodtech.com",
};

type TMODES = keyof typeof apiUrl;

export const IOT_WSS_URL = wssUrl[import.meta.env.MODE as TMODES];
export const TAKO_API = apiUrl[import.meta.env.MODE as TMODES];
export const REGION = "ap-southeast-1";

// in milliseconds
export const STALE_TIME = 900000;
export const CACHE_TIME = 1200000;

export const CHAT_CLIENT_ID = `tako-chat:${import.meta.env.MODE}`;

export const persistenQueryHashes = {
  banned_words: "banned_words",
  get_participant_instance: "get_participant_instance",
};

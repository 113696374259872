import { useMutation } from "@tanstack/react-query";
import { useWebSocket } from "../utils/use_websocket";
import { fetchWithAuth } from "../utils/fetchutils";
import { useFeatureFlag } from "../utils/feature_flag";
import { useTakoContext } from "../tako_context";

export function useSendVote() {
  const { implementation, apiUrl: apiEndpoint } = useWebSocket();

  console.log("use_send_vote.ts: useSendVote(): ", {
    implementation,
    apiEndpoint,
  });

  const { hasV2EndpointsEnabled } = useFeatureFlag();
  const { apiUrl } = useTakoContext();

  return useMutation({
    mutationFn: async (payload: {
      interactivityId: string;
      optionId: string;
      participantId: string;
      groupId: string;
      instanceId: string;
      publishTo?: string;
      correlationId?: string;
    }) => {
      const url = hasV2EndpointsEnabled
        ? `${apiUrl}/v2/votes`
        : `${apiEndpoint}/api/v1/votes`;
      const res = await fetchWithAuth(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) throw new Error("Something went wrong...");

      return res.json();
    },
  });
}

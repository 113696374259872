import "../index.css";
import { useEffect } from "react";
import _debounce from "lodash/debounce";
import JSConfetti from "js-confetti";
import { v4 as uuidv4 } from "uuid";

import { ChatProvider } from "./chat_provider";
import ChatboxInput from "./chatbox_input";
import ChatRules from "./chat_rules";
import ChatLoader from "./chat_loader";
import SignInButton from "./sign_in_button";
import Messages from "./messages";

import { useWebSocketEventSubscribe } from "../utils/events";
import { useFeatureFlag } from "../utils/feature_flag";
import { useThemeContext } from "../utils/themes";

export type ChatProps = {
  userId?: string;
  displayName?: string;
  onLogin?: () => void;
};

export default function Chat({ userId, onLogin }: ChatProps) {
  const { chat: chatStyle, isBentoTheme } = useThemeContext();
  const { hasErrorRandomizer } = useFeatureFlag();

  useEffect(() => {
    if (hasErrorRandomizer) {
      let errRandomizer = Math.random() * 100;
      console.log("[tako]", { errRandomizer });

      if (errRandomizer < 50) throw new Error("[tako] Something happened");
    }
  }, []);

  // TEMP: only triggered when confetti button is clicked on the admin panel
  const jsConfetti = new JSConfetti();

  useWebSocketEventSubscribe(
    "yassified",
    uuidv4(),
    () => {
      jsConfetti.addConfetti();
    },
    []
  );

  return (
    <ChatProvider participantId={userId}>
      <div
        className="flex flex-col flex-grow w-full h-full shadow-xl rounded-lg overflow-hidden relative"
        style={{
          backgroundColor: isBentoTheme
            ? "#FFFFFF"
            : chatStyle.colors.background,
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundImage: `url(${chatStyle.backgroundImage?.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            filter: chatStyle.backgroundImage?.blur ? "blur(5px)" : "",
          }}
        />

        <ChatRules />

        <ChatLoader />

        <Messages />

        {!!userId ? <ChatboxInput /> : <SignInButton onLogin={onLogin} />}
      </div>
    </ChatProvider>
  );
}

import { useQuery } from "@tanstack/react-query";
import { useTakoContext } from "../tako_context";
import { useFeatureFlag } from "../utils/feature_flag";
import { useWebSocket } from "../utils/use_websocket";
import { fetchWithAuth } from "../utils/fetchutils";

type Ad = {
  content: {
    imgUrl: string;
    header: string;
    ctaUrl: string;
    ctaLabel: string;
  };
  appearance: {
    bgColor: string;
    ctaColor: string;
    fontSize: string;
    ctaBgColor: string;
    fontFamily: string;
    headerColor: string;
  };
  adsId: string;
};

export function useAds({ adsId }: { adsId: string }) {
  const { implementation, apiUrl: apiEndpoint } = useWebSocket();

  console.log("use_ads.tsx: useAds(): ", {
    implementation,
    apiEndpoint,
  });

  const { apiUrl } = useTakoContext();
  const { hasV2EndpointsEnabled } = useFeatureFlag();
  const baseUrl = hasV2EndpointsEnabled
    ? `${apiUrl}/v2`
    : `${apiEndpoint}/api/v1`;
  const hasApiEndpoint = !!apiEndpoint || !!apiUrl;
  const { data: ad } = useQuery<Ad>({
    queryKey: ["getAdsById", adsId ?? ""],
    queryFn: async ({ queryKey }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(`${baseUrl}/ads/${queryKey[1]}`);

      return res.json();
    },
    enabled: !!adsId,
  });
  const { data: imgUrl } = useQuery<string>({
    queryKey: ["getSignedUrl", ad?.content?.imgUrl ?? ""],
    queryFn: async ({ queryKey }) => {
      if (!hasApiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

      const res = await fetchWithAuth(
        `${baseUrl}/ads/images/${queryKey[1]}/signed_url`
      );

      return res.json();
    },
    enabled: !!ad?.content?.imgUrl,
  });

  return {
    ad,
    imgUrl,
    baseUrl,
  };
}

import { appPrefix } from "./constants";

export const makeItemKey = (itemName: string, userId?: string) => {
  const prefixedKey = appPrefix + ":" + itemName;
  return !userId ? prefixedKey : `${prefixedKey}:u`;
};

export const obf = (text: string) => {
  const textBin = new TextEncoder().encode(text);
  const buff = new Uint8Array(textBin.length + 1);

  const mask = Math.floor(Math.random() * 256);
  buff[0] = mask;
  for (let i = textBin.length - 1, j = 1; i >= 0; i--, j++) {
    buff[j] = textBin[i] ^ mask;
  }

  const base64 = btoa(
    String.fromCharCode.apply(null, buff as unknown as number[])
  );

  return base64;
};

export const deObf = (obData: string) => {
  // obData is expected to be in base64
  const base64 = obData;

  try {
    const buff = new Uint8Array(
      [...atob(base64)].map((c) => c.charCodeAt(0))
    );

    const mask = buff[0];
    const textBin = new Uint8Array(buff.length - 1);

    for (let i = buff.length - 1, j = 0; i >= 1; i--, j++) {
      textBin[j] = buff[i] ^ mask;
    }

    const text = new TextDecoder().decode(textBin);

    return text;
  } catch (err: any) {
    return "";
  }
};
import { memo, useMemo } from "react";
import { TbCheck } from "react-icons/tb";
import { useQuery } from "@tanstack/react-query";
import { useWebSocket } from "../utils/use_websocket";
import { useThemeContext } from "../utils/themes";
import { fetchWithAuth } from "../utils/fetchutils";
import { useTakoContext } from "../tako_context";
import { useFeatureFlag } from "../utils/feature_flag";

const OPTION_TEXT_FONT_SIZE = {
  sm: "14px",
  default: "16px",
  md: "18px",
  lg: "20px",
};

type AnswerProps = {
  onClick: () => void;
  img: string;
  text: string;
  isSelected?: boolean;
  isLocked?: boolean;
  isPrediction?: boolean;
  displayImage?: boolean;
  buttonProps?: { disabled?: boolean };
};

export function InteractivityAnswer({
  buttonProps = {},
  ...props
}: AnswerProps) {
  const { implementation, apiUrl: apiEndpoint } = useWebSocket();

  console.log("interactivity_answer.tsx: InteractivityAnswer: ", {
    implementation,
    apiEndpoint,
  });

  const { apiUrl } = useTakoContext();
  const { hasV2EndpointsEnabled } = useFeatureFlag();
  const { interactivity, isBentoTheme } = useThemeContext();
  const highlightColor = isBentoTheme
    ? "#AD7800"
    : interactivity?.colors.highlight;
  const fontColor = isBentoTheme ? "#000" : interactivity?.font.color;
  const filename = props?.img?.split("/") ?? []; // only get the filename
  const file = useMemo(() => {
    return filename?.[filename?.length - 1] ?? "";
  }, [filename]);
  const { data } = useQuery<{
    imageUrl: string;
  }>({
    queryKey: ["get_presigned_url", file],
    queryFn: async ({ queryKey }) => {
      if (!apiEndpoint) throw new Error("API ENDPOINT UNDEFINED");
      const url = hasV2EndpointsEnabled
        ? `${apiUrl}/v2/images/get_presigned_url?imageUrl=${queryKey[1]}`
        : `${apiEndpoint}/api/v1/images/get_presigned_url?imageUrl=${queryKey[1]}`;

      const res = await fetchWithAuth(url);

      return res.json();
    },
    enabled: !!props?.img,
  });
  const isDisabled =
    !!props.isLocked || !!props.isSelected || buttonProps?.disabled;

  return (
    <button
      className={`w-full  px-[8px] py-[6px] ${
        isDisabled ? "" : "cursor-pointer"
      }`}
      onClick={props.onClick}
      {...buttonProps}
      disabled={isDisabled}
    >
      <div
        className={`flex bg-white bg-opacity-80 w-full rounded-md  h-full  ${
          props.isSelected ? "border-2" : ""
        }`}
        style={{
          borderColor: highlightColor,
        }}
      >
        <div
          className={`w-[40px] h-[40px] min-w-[40px] max-w-[40px] h-full flex justify-center items-center relative overflow-hidden 
            ${props.isSelected ? "" : "rounded-tl-md rounded-bl-md"}`}
          style={{
            backgroundColor: props.isSelected
              ? highlightColor
              : "rgba(255, 255, 255, 0.6)",
          }}
        >
          {props.isSelected ? (
            <TbCheck size="25" color="#fff" />
          ) : (
            props.displayImage && (
              <img
                src={data?.imageUrl ?? props.img}
                className={`m-auto absolute inset-0 object-cover max-w-[50px]  max-h-[50px] ${
                  !props.isSelected ? "" : "rounded-tl-md rounded-bl-md"
                }`}
                alt=""
              />
            )
          )}
        </div>

        <div
          className=" ml-2 mr-2 my-auto  pt-3 pb-3 text-left"
          style={{
            wordBreak: "break-word",
            fontFamily: interactivity?.font.style || "Poppins",
            fontSize:
              OPTION_TEXT_FONT_SIZE[
                interactivity?.font.size as keyof typeof OPTION_TEXT_FONT_SIZE
              ] || "16px",
            color: fontColor,
          }}
        >
          {props.text}
        </div>
      </div>
    </button>
  );
}

export default memo(InteractivityAnswer);

import { Instance } from "./types";
import { useAds } from "./use_ads";

type InteractivityAdDisplayProps = {
  adsId: string;
  participantId: string;
  instance?: Omit<Instance, "groupId">;
  onClose?: () => void;
};

export default function InteractivityAdDisplay(
  props: InteractivityAdDisplayProps
) {
  const { ad, imgUrl, baseUrl } = useAds({ adsId: props.adsId });

  const redirectUrl = new URL(`${baseUrl}/ads`);
  redirectUrl.searchParams.append(
    "redirectUrl",
    ad?.content?.ctaUrl?.includes("http")
      ? encodeURIComponent(ad?.content?.ctaUrl)
      : encodeURIComponent("//" + ad?.content?.ctaUrl)
  );
  redirectUrl.searchParams.append("adsId", props.adsId);
  redirectUrl.searchParams.append("participantId", props.participantId);
  redirectUrl.searchParams.append(
    "instanceId",
    props.instance?.instanceId ?? ""
  );
  redirectUrl.searchParams.append(
    "playlistId",
    props.instance?.playlistId ?? ""
  );

  return (
    <div className="w-full flex flex-col bg-white p-4 items-center">
      <a href={redirectUrl.toString()} target="_blank">
        {imgUrl && (
          <img
            src={imgUrl}
            alt="preview-image"
            className="[@media(min-width:768px)]:h-[400px] [@media(min-width:768px)]:w-[400px] [@media(min-width:768px)]:max-h-[400px] [@media(min-width:320px)]:max-w-[400px] [@media(min-width:320px)]:h-[250px] [@media(min-width:320px)]:w-full [@media(min-width:320px)]:max-h-[250px]"
          />
        )}
        <h2
          className="font-semibold text-lg py-4 text-center"
          style={{
            color: ad?.appearance?.headerColor ?? "#fff",
            backgroundColor: ad?.appearance?.bgColor ?? "#000",
          }}
        >
          {ad?.content?.header ?? ""}
        </h2>
      </a>
      <button
        className="rounded-md py-4 bg-[#EAAC21] text-black mt-2 w-[400px] max-w-full"
        onClick={() => props.onClose?.()}
      >
        {ad?.content?.ctaLabel ?? ""}
      </button>
    </div>
  );
}

import { ulid } from "ulid";
import { useCallback } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { AiOutlineDoubleRight } from "react-icons/ai";

import InteractivityTitlingDisplay from "./interactivity_titling_display";
import { InteractivityPollDisplay } from "./interactivity_poll_display";
import InteractivityPredictionDisplay from "./interactivity_prediction_display";
import { ACTIONS, INTERACTIVITY_TYPES } from "../utils/constants";
import { useThemeContext } from "../utils/themes";
import { useSendVote } from "./use_send_vote";
import { useInstance } from "./use_instance";
import { useInstance as useInstanceStable } from "./use_instance_copy";
import { useWebSocket } from "../utils/use_websocket";
import InteractivityAdDisplay from "./interactivity_ad_display";
import InteractivityWrapper from "./interactivity_wrapper";
import { InstanceLogAction, InteractivityOption } from "./types";
import AgreementDisplay from "./interactivity_agreement_display";
import InteractivityVoucherDisplay from "./interactivity_voucher_display";
import { useFeatureFlag } from "../utils/feature_flag";

interface InteractivityProps {
  className: string;
  userId: string;
  onOptionSelected?: (data: {
    participantId: string;
    instanceId: string;
    groupId: string;
    sceneId: string;
    interactivityId: string;
    optionId: string;
    optionText: string;
    interactivity: {
      interactivityId: string;
      type: string;
    };
  }) => void;
  onInstanceParticipantLoaded?: (data: {
    sceneId: string;
    playlistId: string;
    instanceId: string;
    groupId?: string;
  }) => void;
  optionsProps?: { disabled?: boolean };
  onPreviewOptionSelected?: () => void;
}

export default function Interactivity(props: InteractivityProps) {
  const { hasExperimentalChanges } = useFeatureFlag();
  // util hooks and states
  const {
    titlingClose,
    participantInstance,
    selectOptionMutate,
    interactivity,
    interactivityOptions,
    previewInteractivity,
    instance,
    handleTitlingClose,
    selectedAnswer,
    participantInstanceLoading,
    handleStoreAgreement,
    isAgreementModal,
    adsDisplayOpened,
    setAdsDisplayOpened,
  } = hasExperimentalChanges
    ? useInstance({
        participantId: props?.userId ?? "",
      })
    : useInstanceStable({
        participantId: props?.userId ?? "",
      });
  const { implementation } = useWebSocket();
  const correlationId = ulid();

  const { mutate: sendVote, isLoading: voteLoading } = useSendVote();
  const { interactivity: interStyle, isBentoTheme } = useThemeContext();
  const backgroundColor = isBentoTheme
    ? "#EAAC21"
    : interStyle?.colors.background;
  const highlightColor = isBentoTheme
    ? "#AD7800"
    : interStyle?.colors.highlight;

  const handleAnswerClick = useCallback(
    (option: { optionId: string; optionText: string }) => {
      selectOptionMutate({
        participantId: props?.userId ?? "",
        instanceId: participantInstance?.instanceId ?? "",
        groupId: participantInstance?.groupId ?? "",
        action: ACTIONS.SELECT as InstanceLogAction,
        sceneId: participantInstance?.activeSceneId ?? "",
        type: interactivity?.type ?? "",
        metadata: {
          optionId: option.optionId ?? "",
          optionText: option.optionText ?? "",
          sceneId: participantInstance?.activeSceneId ?? "",
          interactivityId: interactivity?.interactivityId ?? "",
          runId: participantInstance?.runId ?? "",
        },
      });

      sendVote({
        participantId: props?.userId ?? "",
        instanceId: participantInstance?.instanceId ?? "",
        groupId: participantInstance?.groupId ?? "",
        interactivityId: interactivity?.interactivityId ?? "",
        optionId: option.optionId,
        publishTo: implementation,
        correlationId,
      });

      props.onOptionSelected &&
        props.onOptionSelected({
          participantId: props?.userId ?? "",
          instanceId: participantInstance?.instanceId ?? "",
          groupId: participantInstance?.groupId ?? "",
          sceneId: participantInstance?.activeSceneId ?? "",
          optionId: option.optionId ?? "",
          optionText: option.optionText ?? "",
          interactivityId: interactivity?.interactivityId ?? "",
          interactivity: {
            interactivityId: interactivity?.interactivityId ?? "",
            type: interactivity?.type ?? "",
          },
        });
    },
    [interactivity, participantInstance, props?.userId]
  );

  useUpdateEffect(() => {
    if (!!props.onInstanceParticipantLoaded && !participantInstanceLoading) {
      console.log("[tako][inter] => onInstanceParticipantLoaded");
      props.onInstanceParticipantLoaded({
        sceneId: participantInstance?.activeSceneId ?? "",
        playlistId: participantInstance?.playlistId ?? "",
        instanceId: participantInstance?.instanceId ?? "",
        groupId: participantInstance?.groupId ?? "",
      });
    }
  }, [participantInstanceLoading, participantInstance]);

  const renderInteractivityContent = ({
    type,
    metadata,
    options,
    onAnswer,
  }: {
    type: string;
    metadata: any;
    options: InteractivityOption[];
    onAnswer: ({
      optionId,
      optionText,
    }: {
      optionId: string;
      optionText: string;
    }) => void;
  }) => {
    switch (type) {
      case INTERACTIVITY_TYPES.ADS:
        return (
          <InteractivityAdDisplay
            adsId={metadata?.adsId}
            participantId={props?.userId ?? ""}
            instance={instance}
            onClose={() => setAdsDisplayOpened(false)}
          />
        );
      case INTERACTIVITY_TYPES.TITLING:
        return (
          <>
            <InteractivityTitlingDisplay
              title={metadata?.title ?? ""}
              content={metadata?.content ?? ""}
              logoUrl={interStyle.logo}
            />
            <div
              className="rounded-b-lg"
              style={{
                backgroundColor,
                filter: isBentoTheme ? "unset" : "brightness(115%)",
              }}
            >
              <span
                onClick={handleTitlingClose}
                className="flex justify-end  pr-5 pt-2  pb-2 text-md align-middle items-center"
                role="button"
                style={{
                  color: highlightColor,
                  fontFamily: interStyle?.font.style || "Poppins",
                }}
              >
                Dismiss
                <span className="font-[1700] text-[20px]">
                  <AiOutlineDoubleRight />
                </span>
              </span>
            </div>
          </>
        );
      case INTERACTIVITY_TYPES.PREDICTION:
        return (
          <InteractivityPredictionDisplay
            type={type}
            question={metadata?.question || ""}
            isLocked={!!interactivity?.isLocked}
            interactivityOptions={options}
            selectedAnswers={[selectedAnswer ?? ""]}
            handleAnswerClick={onAnswer}
            optionsProps={{
              ...props.optionsProps,
              disabled: voteLoading || props.optionsProps?.disabled,
            }}
            logoUrl={interStyle.logo}
          />
        );
      case INTERACTIVITY_TYPES.AGREEMENT_MODAL:
        return (
          <AgreementDisplay
            isAgreementModal={isAgreementModal}
            agreementModalId={metadata?.agreementModalId}
            handleClick={handleStoreAgreement}
          />
        );

      case INTERACTIVITY_TYPES.POLL:
      case INTERACTIVITY_TYPES.QUIZ:
        return (
          <InteractivityPollDisplay
            type={type}
            question={metadata?.question || ""}
            isLocked={!!interactivity?.isLocked}
            interactivityOptions={options}
            selectedAnswer={selectedAnswer ?? ""}
            handleAnswerClick={onAnswer}
            optionsProps={{
              ...props.optionsProps,
              disabled: voteLoading || props.optionsProps?.disabled,
            }}
            logoUrl={interStyle.logo}
          />
        );
      case INTERACTIVITY_TYPES.VOUCHER:
        return (
          <InteractivityVoucherDisplay
            voucherId={metadata?.voucherId}
            participantId={props?.userId ?? ""}
            instance={instance}
            backgroundColor={backgroundColor}
          />
        );
      default:
        return null;
    }
  };

  // This means that the user is not logged in
  // Only display a preview interactivity
  if (
    !props?.userId &&
    !!instance?.activeSceneId &&
    !!instance?.showDisplay &&
    !!previewInteractivity
  ) {
    return (
      <InteractivityWrapper
        className={props.className}
        nativeStyle={
          interactivity?.type === INTERACTIVITY_TYPES.AGREEMENT_MODAL
            ? true
            : false
        }
      >
        {renderInteractivityContent({
          type: previewInteractivity?.type ?? "",
          metadata: JSON.parse(previewInteractivity?.metadata ?? "{}"),
          options: previewInteractivity?.options ?? [],
          onAnswer: props?.onPreviewOptionSelected ?? (() => {}),
        })}
      </InteractivityWrapper>
    );
  }

  if (!participantInstance?.showDisplay || !interactivity || titlingClose)
    return null;

  return (
    <InteractivityWrapper
      className={props.className}
      nativeStyle={
        interactivity?.type === INTERACTIVITY_TYPES.AGREEMENT_MODAL
          ? true
          : false
      }
      opened={
        interactivity?.type === INTERACTIVITY_TYPES.ADS
          ? adsDisplayOpened
          : true
      }
    >
      {renderInteractivityContent({
        type: interactivity?.type ?? "",
        metadata: JSON.parse(interactivity?.metadata ?? "{}"),
        options: interactivityOptions,
        onAnswer: handleAnswerClick,
      })}
    </InteractivityWrapper>
  );
}

import { createContext, useContext } from "react";

export const TakoContext = createContext<{ apiUrl: string } | undefined>(
  undefined
);

export const useTakoContext = () => {
  const context = useContext(TakoContext);

  if (context === undefined) {
    throw new Error("useTakoContext must be used within a TakoProvider");
  }

  return context;
};

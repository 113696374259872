import { useState } from "react";
import { BsArrowDown } from "react-icons/bs";

export function useScroll({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const [displayScrollButton, setDisplayScrollButton] = useState(false);

  const moveToBottom = (force = false) => {
    if (!!containerRef.current) {
      // auto adjust to bottom if user view is within 14% from the bottom
      if (isAtBottom(14) || force)
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const scrollToBottom = (force = false, containerId: string = "dummydiv") => {
    if (!!containerRef.current) {
      if (!isAtBottom(14) || force) {
        setTimeout(() => {
          (containerRef.current as any).scroll({
            top: (containerRef.current as any).scrollHeight,
            behavior: "smooth",
          });
        }, 69);
      }
    }
  };

  const isAtBottom = (percentage: number = 14) => {
    if (!!containerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
      const currentView = scrollHeight - scrollTop;
      return (
        Math.floor(((currentView - clientHeight) / scrollHeight) * 100) <=
        percentage
      );
    }

    return false;
  };

  const handleDisplayScrollDownButton = () => {
    const atBottom = isAtBottom(14);

    // only set the state if isAtBottom value is not different to the current state
    if (displayScrollButton !== !atBottom) {
      setDisplayScrollButton(!atBottom);
    }
  };

  containerRef.current?.addEventListener(
    "scroll",
    handleDisplayScrollDownButton
  );

  const ScrollDownButton = ({
    scrollTo = "dummydiv",
  }: {
    scrollTo: string;
  }) => {
    if (!displayScrollButton) return null;

    return (
      <div
        className="bg-[#41B8EA] rounded-full absolute bottom-24 mx-auto left-1/2 hover:bg-[#3493bb] cursor-pointer"
        onClick={() => scrollToBottom(true, scrollTo)}
      >
        <BsArrowDown size={25} className="flex-none m-2 text-white" />
      </div>
    );
  };

  return {
    moveToBottom,
    scrollToBottom,
    isAtBottom,
    ScrollDownButton,
    handleDisplayScrollDownButton,
  };
}

import { lazy, useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import { ulid } from "ulid";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ChatProps } from "./chat/Chat";
import {
  ExperimentalFeatures,
  FeatureFlagProvider,
} from "./utils/feature_flag";
import { CHAT_CLIENT_ID, TAKO_API } from "./constants";
import ErrorHandler from "./error_handler";
import { TakoContext } from "./tako_context";
import { ThemeProvider } from "./utils/theme_provider";
import { correlationIdAtom, sessionIdAtom, sourceStore } from "./atoms";
import { WebSocketProvider } from "./utils/use_websocket";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ReactQueryDevtoolsForPlayground = lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

export type TakoProviderProps = {
  iotWssUrl?: string;
  apiUrl?: string;
  userId?: string;
  sessionId?: string;
  correlationId?: string;
  hasIotCredentialsCaching?: boolean;
  wssImplementation?: "iot" | "bento";
};

export default function TakoProvider(
  props: TakoProviderProps &
    ChatProps & {
      experimentalFeatures?: ExperimentalFeatures;
      children: React.ReactNode;
    } & {
      showReactQueryDevTools?: boolean;
    }
) {
  const { apiUrl, iotWssUrl, experimentalFeatures, ...chatProps } = props;
  const [chatSessionId, setChatSessionId] = useAtom(sessionIdAtom);
  const setCorrelationId = useSetAtom(correlationIdAtom);
  const [source, setSource] = useAtom(sourceStore);

  const api = apiUrl || TAKO_API;

  const cId = ulid();

  useEffect(() => {
    // console.info(
    //   "[tako] tako_provider.tsx: Loading component with the following features: ",
    //   experimentalFeatures
    // );

    const sessId = `${CHAT_CLIENT_ID}:${ulid()}`;
    setChatSessionId(sessId);
    setCorrelationId(cId);
    console.info("[tako] tako_provider.tsx: Chat session ID: ", sessId);

    // set source if ?src query param is present
    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get("src") || source || "KOL";
    setSource(src);
    console.info("[tako] tako_provider.tsx: source: ", src);

    return () => {
      setCorrelationId("");
      setChatSessionId("");
      setSource("");
    };
  }, []);

  const isBentows = experimentalFeatures?.hasBentoWSEnabled ?? false;
  console.log("[bentows] tako_provider.tsx: TakoProvider: ", {
    isBentows,
    userId: props.userId,
  });

  return (
    <TakoContext.Provider value={{ apiUrl: api }}>
      <FeatureFlagProvider experimentalFeatures={experimentalFeatures}>
        <QueryClientProvider client={queryClient}>
          <WebSocketProvider
            implementation={
              props.wssImplementation ?? (isBentows ? "bento" : "iot")
            }
            iotApiUrl={api}
            iotWssUrl={props.iotWssUrl ?? import.meta.env.VITE_IOT_WSS_URL}
            bentoWsApiUrl={api}
            bentoWsWssUrl={
              props.iotWssUrl ?? import.meta.env.VITE_WS_ENDPOINT_URL
            }
            sessionId={chatSessionId}
            correlationId={cId}
            userId={props.userId}
          >
            <ThemeProvider>
              <ErrorHandler>{props.children}</ErrorHandler>
            </ThemeProvider>
          </WebSocketProvider>
          {!!props.showReactQueryDevTools && (
            <ReactQueryDevtoolsForPlayground initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </FeatureFlagProvider>
    </TakoContext.Provider>
  );
}

export const LocalStorage = {
  set: <T>(key: string, val: T) => {
    localStorage.setItem(key, JSON.stringify(val));
  },

  get: <T>(key: string): T | null => {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        const ret = JSON.parse(item) as T;
        return ret;
      } catch (err: any) {
        // Ignore
      }
    }

    return null;
  },

  remove: <T>(key: string) => {
    localStorage.removeItem(key);
  },
};

import { useCallback, useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { useAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import { ulid } from "ulid";
import { EventReceivedFunction, InteractivityData } from "./utils/event_utils";
import { useWebSocketEventSubscribe } from "./utils/events";
import { useWebSocket } from "./utils/use_websocket";
import { interactivityStore } from "./atoms";
import { useGetInstanceParticipantsById } from "./queries/instance_participant";

type EventListenerProps = {
  onEventReceived: EventReceivedFunction;
  onInteractivityLoaded?: (interactivity: InteractivityData) => void;
  userId: string;
};

export default function EventListener({
  onEventReceived,
  onInteractivityLoaded = () => {},
  userId,
}: EventListenerProps) {
  const [interactivity] = useAtom(interactivityStore);
  const {
    data: instanceParticipant,
    refetch,
    isRefetching,
    fetchStatus,
    status,
  } = useGetInstanceParticipantsById(userId);

  const { implementation } = useWebSocket();
  const correlationId = ulid();

  const [isEndScene, setIsEndScene] = useState(false);

  useUpdateEffect(() => {
    if (
      !!isEndScene &&
      !isRefetching &&
      status === "success" &&
      fetchStatus === "idle"
    ) {
      console.log(
        "[tako][inter][event] :: handle assign groups, your new groupId is ",
        instanceParticipant?.groupId
      );
      onEventReceived("assigned_groups", {
        [userId]: instanceParticipant?.groupId,
      });
      setIsEndScene(false);
    }
  }, [status, isRefetching, fetchStatus, instanceParticipant, isEndScene]);

  useUpdateEffect(() => {
    if (interactivity) {
      console.log("[tako][inter][event] => interactivity loaded");
      onInteractivityLoaded({
        interactivityId: interactivity.interactivityId,
        type: interactivity.type as any,
        sceneId: interactivity.sceneId,
      });
    }
  }, [interactivity]);

  const handlePlaylistStarted = useCallback(
    (payload: unknown) => {
      const data = payload as any;
      console.log(
        "[tako][inter][event] => (playlist_started) GROUP ID",
        data.groupId
      );
      onEventReceived("playlist_started", { ...data });
    },
    [instanceParticipant?.groupId]
  );

  const handlePlaylistEnded = useCallback(
    (payload: unknown) => {
      const data = payload as object;
      console.log(
        "[tako][inter][event] => (playlist_ended) GROUP ID",
        instanceParticipant?.groupId
      );
      onEventReceived("playlist_ended", {
        ...data,
        groupId: instanceParticipant?.groupId,
      });
    },
    [instanceParticipant?.groupId]
  );

  const handleSceneStarted = useCallback(
    (payload: unknown) => {
      const data = payload as object;
      console.log(
        "[tako][inter][event] => (scene_started) GROUP ID",
        instanceParticipant?.groupId
      );
      onEventReceived("scene_started", {
        ...data,
        groupId: instanceParticipant?.groupId,
      });
    },
    [instanceParticipant?.groupId]
  );

  const handleSceneEnded = useCallback(
    (payload: unknown) => {
      const data = payload as object;
      console.log(
        "[tako][inter][event] => (scene_ended) previous groupId",
        instanceParticipant?.groupId
      );
      onEventReceived("scene_ended", {
        ...data,
        groupId: instanceParticipant?.groupId,
      });
    },
    [instanceParticipant?.groupId]
  );

  const handleAssignedGroups = useCallback(() => {
    refetch();
    setIsEndScene(true);
  }, [userId, instanceParticipant?.groupId]);

  useWebSocketEventSubscribe(
    "playlist_started",
    uuidv4(),
    handlePlaylistStarted,
    [instanceParticipant?.groupId]
  );

  useWebSocketEventSubscribe("playlist_ended", uuidv4(), handlePlaylistEnded, [
    instanceParticipant?.groupId,
  ]);

  useWebSocketEventSubscribe("scene_started", uuidv4(), handleSceneStarted, [
    instanceParticipant?.groupId,
  ]);

  useWebSocketEventSubscribe("scene_ended", uuidv4(), handleSceneEnded, [
    instanceParticipant?.groupId,
  ]);

  useWebSocketEventSubscribe(
    "assigned_groups",
    uuidv4(),
    handleAssignedGroups,
    [instanceParticipant?.groupId]
  );

  return <></>;
}

import * as React from "react";

export interface IInteractivityLogoProps {
  url: string;
  mt?: string;
  mb?: string;
}

export default function InteractivityLogo({
  url,
  mt,
  mb,
}: IInteractivityLogoProps) {
  return (
    <div
      className={
        "w-[80px] md:w-[130px] mx-auto m  " + (mt ?? "mt-4 ") + (mb ?? "mb-4")
      }
    >
      <img src={url} alt="logo" />
    </div>
  );
}

import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Color from "color";
import { useAtom } from "jotai";
import { BsPinAngleFill, BsChevronUp } from "react-icons/bs";

import { checkAnchorTag } from "../utils/formatUrls";
import { isPromptStore } from "../atoms";

type MessageWithLink = {
  isbentotheme: string;
  interstyle: {
    colors: {
      highlight: string;
    };
  };
};

const MessageWithLink = styled.p<MessageWithLink>`
  & a {
    color: ${({ isbentotheme, interstyle }: MessageWithLink) =>
      isbentotheme === "true" || interstyle.colors.highlight.at(0) !== "#"
        ? "blue"
        : Color(interstyle.colors.highlight).lighten(0.5).hex()} !important;
  }
`;
type HighlightPropsType = {
  interStyle: any;
  isBentoTheme: boolean;
  userName: string;
  isMobile: boolean;
  isClamped: boolean;
  isHighlighted: boolean;
  isCensored: boolean;
  spanRef: any;
  message: string;
  toggleExpand: () => void;
};
function MessageHighlight({
  interStyle,
  isBentoTheme,
  userName,
  isMobile,
  isClamped,
  isHighlighted,
  isCensored,
  spanRef,
  message,
  toggleExpand,
}: HighlightPropsType) {
  const messageLinkOnlyIsClamped = checkAnchorTag(message);
  const [isBlurred, setIsBlurred] = useState(false);
  const [isPrompt] = useAtom(isPromptStore);

  const blurredMountedRef = useRef(false);

  useEffect(() => {
    blurredMountedRef.current = true;

    setIsBlurred(true);
    setTimeout(() => {
      if (blurredMountedRef.current) {
        setIsBlurred(false);
      }
    }, 250);

    return () => {
      blurredMountedRef.current = false;
    };
  }, [isClamped, isPrompt]);

  return (
    <div>
      <div
        className={`w-full mt-2 space-x-3 px-2 absolute transition-all ease-in-out delay-150 top-0 z-[5]`}
        onClick={toggleExpand}
      >
        <div
          className="rounded-lg"
          style={{
            border: `solid 4px ${
              isBentoTheme ? "#f1d4a3" : interStyle.colors.highlight
            }4D`,
          }}
        >
          <div
            className="text-white rounded-md p-2"
            style={{
              backgroundColor: `${
                isBentoTheme ? "#DB8700" : interStyle.colors.highlight
              }`,
            }}
          >
            <span className="text-xs text-white leading-none relative">
              {userName}
            </span>
            <div className="flex justify-between w-full">
              <div
                className={`relative flex text-white rounded-l-lg rounded-br-lg w-full max-w-full break-all pr-3 transition-all duration-500 ease-in-out ${
                  isClamped
                    ? "max-h-[30px] overflow-hidden"
                    : "max-h-[300px] overflow-visible"
                }`}
                ref={spanRef}
              >
                {isHighlighted && (
                  <BsPinAngleFill size={12} className="flex-none mr-1 mt-2" />
                )}
                <MessageWithLink
                  style={{ wordBreak: "break-word" }}
                  className={`transition-colors duration-300 ${
                    isCensored ? "text-red-600" : "text-inherit"
                  }`}
                  interstyle={interStyle}
                  isbentotheme={`${isBentoTheme}`}
                >
                  {!isCensored ? (
                    <span
                      id="highlightedMessage"
                      className={`${
                        isMobile && isClamped ? "line-clamp-1 " : ""
                      } transition-all duration-300  ${
                        isBlurred ? "blur-[2px]" : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: isClamped ? messageLinkOnlyIsClamped : message,
                      }}
                    ></span>
                  ) : (
                    "This message has been flagged inappropriate."
                  )}
                </MessageWithLink>

                {/* {isMobile && isClamped && (
              <div
                className="flex items-center justify-center text-[13px] font-extrabold cursor-pointer  gap-1"
                onClick={toggleExpand}
              >
                <BsChevronDown style={{ strokeWidth: "2" }} />
              </div>
            )}
            {!isMobile && !isClamped && (
              <div
                className="flex items-center justify-center text-[13px] font-extrabold cursor-pointer  gap-1"
                onClick={toggleExpand}
              >
                <BsChevronUp style={{ strokeWidth: "2" }} />
              </div>
            )} */}
              </div>
              <BsChevronUp
                style={{ strokeWidth: "2" }}
                size={12}
                className={`flex-none mr-1 mt-2 transition-transform duration-500 right-0 ${
                  isClamped ? "rotate-180" : ""
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageHighlight;

import { useAtom } from "jotai";
import { useState } from "react";
import { takoIdAtom } from "./atoms";
import LoginDialog from "./login_dialog";
import RegisterDialog from "./register_dialog";
import { LocalStorage } from "./lib/localStorage";

// /* Block #1
// -- Uncomment this block if you want to hardcode the 'components' package source for dev purposes.
// -- Ensure first that Block #2 below has been commented out.
import {
  TakoProvider,
  Interactivity,
  ChatBarebone,
  EventListener,
  extractEventPayload,
} from "../../components/src";

// */

/* Block #2
// -- Uncomment this block if you want to use the '@kroma-pt/tako-chat' npm package directly.
// -- Ensure first that:
// -- 1) The '@kroma-pt/tako-chat' npm package has been installed in the playground project, and
// -- 2) Block #1 above has been commented out.
import {
  TakoProvider,
  Interactivity,
  ChatBarebone,
  EventListener,
  extractEventPayload,
} from "@kroma-pt/tako-chat";
import "@kroma-pt/tako-chat/dist/style.css";
*/

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [takoId, setTakoId] = useAtom(takoIdAtom);

  const hasBentoWSEnabled =
    (import.meta.env.VITE_HAS_BENTO_WS_ENABLED ?? "") === "true";

  return (
    <div className="bg-stone-200">
      <header className="flex justify-between w-full h-20 bg-white p-4">
        <div />
        {takoId !== "" ? (
          <button
            className="text-black border-2 border-solid border-black py-2 px-4 rounded hover:text-white hover:bg-black"
            onClick={() => {
              setTakoId("");
              LocalStorage.set("accessToken", "");
              LocalStorage.set("expiresIn", 0);
              LocalStorage.set("refreshToken", "");
            }}
          >
            Logout
          </button>
        ) : (
          <div className="flex gap-4">
            <button
              className="bg-yellow-500 hover:bg-yellow-400 py-2 px-4 rounded text-white"
              onClick={() => setShowLogin(true)}
            >
              Login
            </button>
            <button
              className="text-black border-2 border-solid border-black py-2 px-4 rounded hover:text-white hover:bg-black"
              onClick={() => setShowRegister(true)}
            >
              Register
            </button>
          </div>
        )}
      </header>
      <LoginDialog opened={showLogin} onClose={() => setShowLogin(false)} />
      <RegisterDialog
        opened={showRegister}
        onClose={() => setShowRegister(false)}
      />
      <div className="p-4">
        <div className="grid grid-cols-12 w-full gap-4">
          <div className="w-full col-span-12 lg:col-span-8">
            {import.meta.env.VITE_ADMIN_URL ? (
              <iframe
                width="100%"
                height="800px"
                src={`${
                  import.meta.env.VITE_ADMIN_URL
                }/playout_manager_tab_play`}
              />
            ) : (
              <iframe
                width="100%"
                height="800px"
                src="https://www.youtube-nocookie.com/embed/XhPG9gk4M-Q"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )}
          </div>
          <div className="w-full col-span-12 lg:col-span-4 relative min-h-[600px]">
            <TakoProvider
              experimentalFeatures={{
                hasBentoWSEnabled,
                hasReactionsEnabled: true,
                hasReplyEnabled: true,
                hasBannedWordsEnabled: true,
                hasPausedUsersEnabled: true,
                hasLearnMoreEnabled: true,
                hasErrorRandomizer: import.meta.env.VITE_HAS_ERROR_RANDOMIZER,
                hasThemesEnabled: true,
                hasIotCredentialsCaching:
                  (import.meta.env.VITE_HAS_IOT_CREDENTIALS_CACHING ?? "") ===
                  "true",
                hasExperimentalHooksEnabled:
                  (import.meta.env.VITE_HAS_EXPERIMENTAL_HOOKS_ENABLED ??
                    "") === "true",
                hasV2EndpointsEnabled:
                  (import.meta.env.VITE_HAS_V2_ENDPOINTS_ENABLED ?? "") ===
                  "true",
              }}
              apiUrl={import.meta.env.VITE_API_URL}
              iotWssUrl={
                hasBentoWSEnabled
                  ? import.meta.env.VITE_WS_ENDPOINT_URL
                  : import.meta.env.VITE_IOT_WSS_URL
              }
              userId={takoId}
              onLogin={() => setShowLogin(true)}
            >
              <ChatBarebone
                userId={takoId}
                onLogin={() => setShowLogin(true)}
              />
              <Interactivity
                className="absolute top-0 w-full p-5"
                userId={takoId}
                onOptionSelected={(selected) => {
                  console.log("answer selected", { selected });
                }}
                onInstanceParticipantLoaded={(ev) => {
                  console.log("onInstanceParticipantLoaded", ev);
                }}
                onPreviewOptionSelected={() => setShowLogin(true)}
              />

              <EventListener
                userId={takoId}
                onInteractivityLoaded={(interactivity) => {
                  console.log(
                    "EventListener - onInteractivityLoaded",
                    interactivity
                  );
                }}
                onEventReceived={(ev, payload) => {
                  console.log("EventListener - onEventReceived", {
                    ev,
                    payload,
                  });

                  if (ev === "scene_started") {
                    const result = extractEventPayload(ev, payload);

                    // Do something here
                    console.log("scene_started event", result);
                  } else if (ev === "scene_ended") {
                    const result = extractEventPayload(ev, payload);

                    // Do something here
                    console.log("scene_ended event", result);
                  } else if (ev === "playlist_started") {
                    const result = extractEventPayload(ev, payload);

                    // Do something here
                    console.log("playlist_started event", result);
                  } else if (ev === "playlist_ended") {
                    const result = extractEventPayload(ev, payload);

                    // Do something here
                    console.log("playlist_ended event", result);
                  }

                  if (ev === "assigned_groups") {
                    const result = extractEventPayload(ev, payload);

                    // Do smething
                    console.log("assigned_groups event", result);
                  }
                }}
              />
            </TakoProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

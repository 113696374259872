import { getAccessToken } from "../utils/localstorage";

export const fetchWithAuth = async (
  url: string,
  init?: RequestInit
): Promise<Response> => {
  const opts: RequestInit = { ...(init ?? {}) };

  const accessToken = getAccessToken();
  if (accessToken) {
    if (!opts.headers) {
      opts.headers = {};
    }

    (opts.headers as Record<string, string>)[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  }

  return fetch(url, opts);
};

import { createContext, ReactNode, useContext } from "react";

export type ExperimentalFeatures = {
  hasReactionsEnabled?: boolean;
  hasReplyEnabled?: boolean;
  hasBannedWordsEnabled?: boolean;
  hasPausedUsersEnabled?: boolean;
  hasLearnMoreEnabled?: boolean;
  hasErrorRandomizer?: boolean;
  hasThemesEnabled?: boolean;
  hasIotCredentialsCaching?: boolean;
  hasExperimentalHooksEnabled?: boolean;
  hasV2EndpointsEnabled?: boolean;
  hasBentoWSEnabled?: boolean;
  hasExperimentalChanges?: boolean;
};

export type FeatureFlagProviderProps = {
  experimentalFeatures?: ExperimentalFeatures;
  children: ReactNode;
};

export const FeatureFlagContext = createContext<ExperimentalFeatures>({});

export function FeatureFlagProvider(props: FeatureFlagProviderProps) {
  return (
    <FeatureFlagContext.Provider
      value={{
        ...props.experimentalFeatures,
      }}
    >
      {props.children}
    </FeatureFlagContext.Provider>
  );
}

export function useFeatureFlag() {
  const values = useContext(FeatureFlagContext);

  return values;
}

import { useQuery } from "@tanstack/react-query";
import { useTakoContext } from "../tako_context";
import { fetchWithAuth } from "../utils/fetchutils";
import { CACHE_TIME, STALE_TIME } from "../constants";

export function useGetBannedWords() {
  const { apiUrl } = useTakoContext();

  return useQuery<{ bannedWord: string }[]>({
    queryKey: ["banned_words"],
    queryFn: async () => {
      if (!apiUrl) throw new Error("API ENDPOINT UNDEFINED");

      const url = `${apiUrl}/v2/messages/banned_words`;

      const res = await fetchWithAuth(url);

      if (!res.ok) throw new Error("Something went wrong");

      return res.json();
    },
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
  });
}

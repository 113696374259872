import { useCallback } from "react";
import Like from "../assets/reactions/thumbsup.png";
import Angry from "../assets/reactions/angry.png";
import Heart from "../assets/reactions/heart.png";
import Funny from "../assets/reactions/funny.png";
import Sad from "../assets/reactions/sad.png";
import Downvote from "../assets/reactions/thumbsdown.png";
import { Reactions } from "./reactions_types";
import { useCreateReaction, useDeleteReaction } from "../mutations/reactions";
import {
  generateBatchGetReactionByMessageIdKey,
  useBatchGetCurrUserReactionByMessageId,
} from "../queries/reactions";
import { useQueryClient } from "@tanstack/react-query";
import { useWebSocket } from "../utils/use_websocket";

export default function ReactionPopoverList(props: {
  messageId: string;
  loggedInUserId?: string;
  onClose?: () => void;
  currUserReaction?: {
    reaction: string;
    reactionId: string;
  };
  correlationId?: string;
}) {
  const { implementation } = useWebSocket();
  const correlationId = props.correlationId;

  console.log("reaction_popover_list.tsx: ReactionPopoverList: ", {
    implementation,
    correlationId,
  });

  const ctx = useQueryClient();
  ("");
  const { mutate: createReaction, isLoading: createReactionLoading } =
    useCreateReaction(props.messageId);

  const { mutate: deleteReaction, isLoading: deleteReactionLoading } =
    useDeleteReaction(props.messageId);

  let currReaction = props.currUserReaction?.reaction ?? "";

  console.log({ currReaction });

  // const handleDelete = (reactionId: string) => {
  //   if (!publish) return;

  //   const topicPayload = {
  //     reactionId: reactionId,
  //     messageId: props.messageId ?? "",
  //   };

  //   console.log(
  //     "[tako] reaction_popover_list.tsx: Sending message to delete_react topic: ",
  //     topicPayload
  //   );
  //   publish("delete_react", {
  //     action: "delete_react",
  //     data: topicPayload,
  //   });
  // };

  const handleReact = useCallback(
    (reaction: string) => {
      if (currReaction === reaction) {
        const payload = {
          reactionId: props.currUserReaction?.reactionId ?? "",
          messageId: props.messageId,
          userId: props.loggedInUserId ?? "",
          publishTo: implementation,
          correlationId,
        };

        console.log("DELETE REACTION", payload);
        deleteReaction(payload, {
          onSuccess() {
            ctx.invalidateQueries(
              generateBatchGetReactionByMessageIdKey(props.messageId)
            );
          },
        });
        return;
      }

      createReaction(
        {
          reaction,
          messageId: props.messageId,
          userId: props.loggedInUserId ?? "",
          publishTo: implementation,
          correlationId,
        },
        {
          onSuccess() {
            ctx.invalidateQueries(
              generateBatchGetReactionByMessageIdKey(props.messageId)
            );
          },
        }
      );
    },
    [props.messageId, props.loggedInUserId, currReaction]
  );

  return (
    <div className="bg-white rounded-md flex gap-4 flex-nowrap w-[400px]">
      <button
        className={`rounded-tl-md rounded-bl-md px-4 py-2 h-full w-full ${
          currReaction === "like" ? "bg-[#ABC6D7]" : ""
        }`}
        onClick={() => {
          handleReact(Reactions.like);
          props.onClose && props.onClose();
        }}
      >
        <img src={Like} className="h-5 w-5" />
      </button>
      <button
        className={`px-4 py-2 h-full w-full ${
          currReaction === "heart" ? "bg-[#ABC6D7]" : ""
        }`}
        onClick={() => {
          handleReact(Reactions.heart);
          props.onClose && props.onClose();
        }}
      >
        <img src={Heart} className="h-5 w-5" />
      </button>
      <button
        className={`px-4 py-2 h-full w-full ${
          currReaction === Reactions.funny ? "bg-[#ABC6D7]" : ""
        }`}
        onClick={() => {
          handleReact(Reactions.funny);
          props.onClose && props.onClose();
        }}
      >
        <img src={Funny} className="h-5 w-5" />
      </button>
      <button
        className={`px-4 py-2 h-full w-full ${
          currReaction === Reactions.angry ? "bg-[#ABC6D7]" : ""
        }`}
        onClick={() => {
          handleReact(Reactions.angry);
          props.onClose && props.onClose();
        }}
      >
        <img src={Angry} className="h-5 w-5" />
      </button>
      <button
        className={`px-4 py-2 h-full w-full ${
          currReaction === Reactions.sad ? "bg-[#ABC6D7]" : ""
        }`}
        onClick={() => {
          handleReact(Reactions.sad);
          props.onClose && props.onClose();
        }}
      >
        <img src={Sad} className="h-5 w-5" />
      </button>
      <button
        className={`px-4 py-2 rounded-tr-md rounded-br-md h-full w-full ${
          currReaction === Reactions.downvote ? "bg-[#ABC6D7]" : ""
        }`}
        onClick={() => {
          handleReact(Reactions.downvote);
          props.onClose && props.onClose();
        }}
      >
        <img src={Downvote} className="h-5 w-5" />
      </button>
    </div>
  );
}

export const ACTIONS = {
  START_PLAYLIST: "START_PLAYLIST",
  END_PLAYLIST: "END_PLAYLIST",
  START_SCENE: "START_SCENE",
  END_SCENE: "END_SCENE",
  BROADCAST_SCENE: "BROADCAST_SCENE",
  UNBROADCAST_SCENE: "UNBROADCAST_SCENE",
  LOCK_SCENE: "LOCK_SCENE",
  JOIN: "JOIN",
  DISCONNECT: "DISCONNECT",
  RECONNECT: "RECONNECT",
  SELECT: "SELECT",
  IDLE: "IDLE",
  ASSIGN: "ASSIGN",
  MESSAGE: "MESSAGE",
  UKNOWN: "UKNOWN",
};

export const INTERACTIVITY_TYPES = {
  TITLING: "TITLING",
  PREDICTION: "PREDICTION",
  QUIZ: "QUIZ",
  POLL: "POLL",
  ADS: "ADS",
  AGREEMENT_MODAL: "AGREEMENT_MODAL",
  VOUCHER: "VOUCHER",
};

export const appPrefix = "tako";

export function useGetVoucherFontSize(size: string | number) {
  let desktopFonts = {
    headerFontSize: 24,
    subHeaderFontSize: 12,
    promoFontSize: 34,
    validityFontSize: 14,
    buttonFontSize: 14,
  };

  let mobileFonts = {
    headerFontSize: 18,
    subHeaderFontSize: 8,
    promoFontSize: 26,
    validityFontSize: 12,
    buttonFontSize: 12,
  };

  switch (size) {
    case 0:
    case "0": {
      desktopFonts = {
        headerFontSize: 22,
        subHeaderFontSize: 10,
        promoFontSize: 32,
        validityFontSize: 12,
        buttonFontSize: 12,
      };
      mobileFonts = {
        headerFontSize: 16,
        subHeaderFontSize: 6,
        promoFontSize: 24,
        validityFontSize: 10,
        buttonFontSize: 10,
      };

      break;
    }

    case 66:
    case "66": {
      desktopFonts = {
        headerFontSize: 26,
        subHeaderFontSize: 14,
        promoFontSize: 36,
        validityFontSize: 16,
        buttonFontSize: 16,
      };
      mobileFonts = {
        headerFontSize: 20,
        subHeaderFontSize: 10,
        promoFontSize: 28,
        validityFontSize: 14,
        buttonFontSize: 14,
      };
      break;
    }

    case 99:
    case "99": {
      desktopFonts = {
        headerFontSize: 28,
        subHeaderFontSize: 16,
        promoFontSize: 38,
        validityFontSize: 18,
        buttonFontSize: 18,
      };
      mobileFonts = {
        headerFontSize: 22,
        subHeaderFontSize: 12,
        promoFontSize: 30,
        validityFontSize: 16,
        buttonFontSize: 16,
      };
      break;
    }

    default: {
      desktopFonts = {
        headerFontSize: 24,
        subHeaderFontSize: 12,
        promoFontSize: 34,
        validityFontSize: 14,
        buttonFontSize: 14,
      };

      mobileFonts = {
        headerFontSize: 18,
        subHeaderFontSize: 8,
        promoFontSize: 26,
        validityFontSize: 12,
        buttonFontSize: 12,
      };

      break;
    }
  }

  return {
    desktopFonts,
    mobileFonts,
  };
}

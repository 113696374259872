import { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import TextareaAutoSize from "react-textarea-autosize";
import emojiData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import sanitize from "sanitize-html";
import { VscSmiley } from "react-icons/vsc";
import {
  RiSendPlane2Line,
  RiCloseCircleFill,
  RiAlertFill,
  RiInformationLine,
  RiExternalLinkLine,
} from "react-icons/ri";
import { ulid } from "ulid";
import { BaseEmoji } from "emoji-mart/index";

import { useFeatureFlag } from "../utils/feature_flag";
import { useThemeContext } from "../utils/themes";
import { fetchWithAuth } from "../utils/fetchutils";
import { useTakoContext } from "../tako_context";
import { useWebSocket } from "../utils/use_websocket";
import { connectionIdAtom } from "../atoms";
import { useChatContext } from "./chat_provider";

const CHAT_MESSAGE_LIMIT = 245;

const CHAT_BOX_MESSAGE_FONT_SIZES = {
  sm: "14px",
  default: "16px",
  md: "18px",
  lg: "20px",
};

const ChatboxInput = () => {
  const {
    chat: chatStyle,
    interactivity: interStyle,
    isBentoTheme,
  } = useThemeContext();
  const chatBackground = chatStyle.colors.background;
  const darkChatBackground = isBentoTheme ? "unset" : "brightness(0.3)"; // 70% darker
  const backgroundColor = isBentoTheme
    ? "#DA830F"
    : interStyle.colors.background;
  const {
    hasPausedUsersEnabled,
    hasLearnMoreEnabled,
    hasV2EndpointsEnabled,
    hasExperimentalChanges,
  } = useFeatureFlag();

  const { implementation, apiUrl: apiEndpoint, wsAdapter } = useWebSocket();
  const { getParticipantQuery, states, actions, moveToBottom } =
    useChatContext();

  const participant = getParticipantQuery.data;
  const userStatus = participant?.status ?? "";
  const pausedDuration = participant?.pausedDuration ?? "";
  const parentMessage = states.parentMessage;
  const replyUsername =
    parentMessage?.firstName ?? "" + " " + parentMessage?.lastName ?? "";
  const disabled =
    (getParticipantQuery.data?.isFirstTimeUser as boolean) ||
    states.mqttStatus !== "connected";
  const { cancelReply, handleOpenChatRulesChange } = actions;

  const { apiUrl } = useTakoContext();
  const [connectionId] = useAtom(connectionIdAtom);
  const [chat, setChat] = useState("");
  const [openPicker, setOpenPicker] = useState(false);
  const [openChatRules, setOpenChatRules] = useState(true);
  const [violationWarning, setViolationWarning] = useState(true);

  const send = async () => {
    if (
      !wsAdapter ||
      !wsAdapter.publish ||
      !getParticipantQuery.data ||
      !chat.trim()
    ) {
      return;
    }

    if (!apiEndpoint) throw new Error("API ENDPOINT UNDEFINED");

    cancelReply();
    setChat("");

    const topicPayload = {
      message: sanitize(chat, {
        allowedTags: ["b", "i", "em", "strong"],
        allowedAttributes: {},
      }),
      userId: participant.participantId,
      connectionId,
      parentMessageId: parentMessage?.parentMessageId ?? "",
      parentMessage,
      correlationId: ulid(),
      firstName: participant?.firstName ?? "",
      lastName: participant?.lastName ?? "",
      displayPhotoUrl: participant?.avatarImgUrl ?? "",
    };

    console.log(
      "[tako] chatbox_input.tsx: Sending message to SendChat topic: ",
      topicPayload
    );

    const url = hasV2EndpointsEnabled
      ? `${apiUrl}/v2/messages`
      : `${apiEndpoint}/api/v1/messages`;

    const res = await fetchWithAuth(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        topic: "SendChat",
        contentType: "application/json",
        payload: JSON.stringify({
          action: "sendChat",
          data: topicPayload,
        }),
        payloadType: "SendChatModel",
        publishTo: implementation,
        correlationId: ulid(),
      }),
    });

    if (!res.ok) throw new Error("Something went wrong");

    moveToBottom();
  };

  useEffect(() => {
    if (userStatus === "PAUSED") setViolationWarning(true);
    else setViolationWarning(false);
  }, [userStatus]);
  const pickerRef = useRef<HTMLDivElement>(null);

  const clickMountedRef = useRef(false);
  useEffect(() => {
    clickMountedRef.current = true;

    function handleClickOutside(event: MouseEvent) {
      if (clickMountedRef.current) {
        if (event.target instanceof Node && pickerRef.current) {
          if (!pickerRef.current.contains(event.target)) {
            setOpenPicker(false);
          }
        }
      }
    }

    if (openPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      clickMountedRef.current = false;
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPicker]);
  const renderChatInput = ({ paused }: { paused?: boolean }) => (
    <div>
      {!!parentMessage?.parentMessageId && (
        <div
          className="p-3 rounded-tl-lg rounded-tr-lg flex items-center"
          style={{
            backgroundColor,
          }}
        >
          <span className="text-[#FFFFFF99]">Replying to &nbsp;</span>
          <span className="text-white"> @{replyUsername}</span>
          <div className="flex-grow" />
          <button
            className="text-white"
            onClick={() => {
              cancelReply();
            }}
          >
            <RiCloseCircleFill className="w-5 h-5" />
          </button>
        </div>
      )}

      {openChatRules && hasLearnMoreEnabled && (
        <div className="p-3 bg-[#FFFFFF] flex items-center rounded mb-2">
          <p
            className="text-sm"
            style={{
              color: "#000",
              // color: isBentoTheme ? "#000" : chatStyle.font.color, Disabled due to UI concerns
            }}
          >
            Welcome KatroPIE to our live chat! Remember to guard your privacy
            and abide by our community guidelines.{" "}
            <button
              onClick={() => {
                if (handleOpenChatRulesChange) {
                  handleOpenChatRulesChange(true);
                }
                setOpenChatRules(false);
              }}
            >
              <span className="font-bold">LEARN MORE</span>
            </button>
          </p>
          <div className="flex-grow" />
          <button
            onClick={() => {
              setOpenChatRules(false);
            }}
          >
            <RiCloseCircleFill
              className="w-5 h-5"
              style={{
                color: isBentoTheme ? "#707070" : chatBackground,
                filter: isBentoTheme ? "unset" : darkChatBackground,
              }}
            />
          </button>
        </div>
      )}
      <div className="relative">
        <div
          ref={pickerRef}
          className={`absolute z-[999] bottom-[20%]  left-[-12%] scale-75   md:bottom-[103%]  md:left-0 md:scale-100 ${
            openPicker ? "" : "hidden"
          }`}
        >
          <Picker
            data={emojiData}
            onEmojiSelect={(emoji: BaseEmoji) => setChat(chat + emoji.native)}
          />
        </div>
        <div
          className={`py-3 px-2 flex items-center w-full before:z-10 ${
            parentMessage?.parentMessageId
              ? " before:rounded-br-lg rounded-br-lg"
              : "before:rounded-lg rounded-lg"
          }  bg-white before:border before:border-[${
            parentMessage?.parentMessageId
              ? "#DA830F"
              : isBentoTheme
              ? "#AAAAAA"
              : chatBackground
          }] gap-2 before:content-[''] before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:brightness-[30%]`}
          style={{
            position: "relative",
          }}
        >
          <button
            className="hover:enabled:bg-gray-100 rounded-[50%] p-1 active:enabled:scale-95 z-20"
            onClick={() => setOpenPicker((prev) => !prev)}
          >
            <VscSmiley
              size={26}
              color={isBentoTheme ? "#BDBDBD" : interStyle.colors.highlight}
              className="stroke-[0.5] brightness-[30%]"
            />
          </button>
          {/* <Popover
          isOpen={openPicker}
          content={
            <div>
              <Picker
                data={emojiData}
                onEmojiSelect={(emoji: BaseEmoji) =>
                  setChat(chat + emoji.native)
                }
              />
            </div>
          }
          onClickOutside={() => setOpenPicker(false)}
          containerClassName="z-40"
        >
          <button
            className="hover:enabled:bg-gray-100 rounded-[50%] p-1 active:enabled:scale-95 z-20"
            onClick={() => setOpenPicker(!openPicker)}
            disabled={disabled || paused}
          >
            <VscSmiley
              size={26}
              color={isBentoTheme ? "#BDBDBD" : interStyle.colors.highlight}
              className="stroke-[0.5] brightness-[30%]"
            />
          </button>
        </Popover> */}

          <TextareaAutoSize
            className="bg-inherit h-[40px] w-full rounded outline-none resize-none scrollbar-hide py-[6px] px-[6px] placeholder:pl-1 text-black z-10"
            id="chat"
            onChange={(ev) => setChat(ev.target.value)}
            onKeyDown={(ev) => {
              if (chat.length !== 0) {
                if (ev.key === "Enter") {
                  if (ev.shiftKey) return;
                  ev.preventDefault();
                  send();
                }
              }
            }}
            maxLength={CHAT_MESSAGE_LIMIT}
            placeholder="type message here"
            value={chat}
            maxRows={7}
            disabled={hasExperimentalChanges ? false : disabled || paused}
            style={{
              fontSize:
                CHAT_BOX_MESSAGE_FONT_SIZES[
                  chatStyle?.font
                    ?.size as keyof typeof CHAT_BOX_MESSAGE_FONT_SIZES
                ] || "16px",
              fontFamily: chatStyle?.font?.style || "Poppins",
              color: "#000",
              // color: isBentoTheme ? "#000" : chatStyle?.font?.color, Disabled due to UI concerns
            }}
          />
          <button
            className="hover:enabled:bg-gray-100 rounded-[50%] p-1 enabled:active:scale-95 disabled:text-[#AAAAAA] text-[#EAAC21] z-20"
            onClick={() => {
              send();
            }}
            disabled={
              hasExperimentalChanges ? false : chat.length === 0 || disabled
            }
          >
            <RiSendPlane2Line
              color={isBentoTheme ? "#F2994A" : interStyle.colors.highlight}
              size={26}
            />
          </button>
        </div>
      </div>
      <div
        className={`${
          hasLearnMoreEnabled ? " flex justify-between items-center" : ""
        }`}
      >
        {hasLearnMoreEnabled && (
          <button
            onClick={() => {
              setOpenChatRules(true);
            }}
          >
            <RiInformationLine
              color={isBentoTheme ? "#B0B0B0" : chatBackground}
              style={{
                filter: darkChatBackground,
              }}
              size={20}
            />
          </button>
        )}

        <p
          className="text-right text-xs font-inter font-semibold"
          style={{
            color: isBentoTheme ? "#000" : chatBackground,
            filter: darkChatBackground,
          }}
        >
          <span
            className={chat.length >= CHAT_MESSAGE_LIMIT ? "text-red-500" : ""}
          >
            {chat.length}
          </span>
          /{CHAT_MESSAGE_LIMIT}
        </p>
      </div>
    </div>
  );

  return (
    <div className="p-4 mt-auto flex flex-col gap-2 z-30">
      {!!hasPausedUsersEnabled && !!violationWarning && (
        <div>
          <div className="py-3 px-4 flex flex-col items-center w-full rounded-lg bg-[#DB8700] border border-[#EEEEEE] gap-2">
            <p className="text-left mt-2 text-sm font-normal text-white">
              <span className="inline-block pr-1">
                <RiAlertFill size={20} />
              </span>{" "}
              Hello KatroPIE, we’re sorry to say that we've had to temporarily
              pause your chat access due to violation against our chat community
              standard, which you can review{" "}
              <span className="inline-block">
                <RiExternalLinkLine size={14} />
              </span>
              <span className="font-bold">here</span>. Don't worry though,
              you'll be able to jump back in as soon as the pause is lifted
              after {pausedDuration}. Meantime, enjoy the show!
            </p>
            <button
              className="w-[56px] h-[35px] opacity-70 hover:opacity-100 mt-4 active:scale-[.995] border border-white text-white text-sm rounded-md"
              onClick={() => setViolationWarning(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
      {renderChatInput({
        paused: !!hasPausedUsersEnabled && userStatus === "PAUSED",
      })}
    </div>
  );
};

export default ChatboxInput;

import { useEffect, useState, useRef, useCallback } from "react";
import { BsPinAngleFill, BsReplyFill } from "react-icons/bs";
import { useInView } from "react-intersection-observer";
import Color from "color";
import styled from "styled-components";

import MessageHighlight from "./message_highlight";
import MessageReactions from "./message_reactions";
import ReactionPopover from "./reaction_popover";
import MessageUsername from "./message_username";

import { useFeatureFlag } from "../utils/feature_flag";
import { clean } from "../utils/string";
import { toAmPmFormat } from "../utils/time_format";
import { useThemeContext } from "../utils/themes";
import { useBatchGetParticipantById } from "../queries/participants";
import { useBatchGetCurrUserReactionByMessageId } from "../queries/reactions";
import { useChatContext } from "./chat_provider";

type ParentMessage = {
  parentMessageId: string;
  userId: string;
  content: string;
  firstName: string;
  lastName: string;
  displayPhotoUrl?: string | null;
  createdAt: number;
  isCensored: boolean;
};

type UserReaction = {
  reaction: string;
  reactionId: string;
};

type MessageProps = {
  content: string;
  name: string;
  isHighlighted?: boolean;
  isCensored?: boolean;
  ref?: any;
  userId: string;
  messageId: string;
  loggedInUserId?: string;
  parentMessageId?: string;
  handleReplyClick?: (parentMessage: ParentMessage) => void;
  createdAt: number;
  displayPhotoUrl: string | null;
  reactions?: {
    likeCount: number;
    heartCount: number;
    funnyCount: number;
    angryCount: number;
    sadCount: number;
    downvoteCount: number;
    currUserReactions?: UserReaction[];
    raw: {
      messageId: string;
      userId?: string | undefined;
      reaction: string;
      reactionId: string;
    }[];
  };
  userStatus: string;
  isScrolling?: boolean;
  messageRef?: React.RefObject<HTMLDivElement>;
};

const PROF_INITIALS_FONT_SIZES = {
  sm: "12px",
  default: "14px",
  md: "16px",
  lg: "18px",
};

const NAME_FONT_SIZES = {
  sm: "10px",
  default: "12px",
  md: "14px",
  lg: "16px",
};

const TIME_FONT_SIZES = {
  sm: "8px",
  default: "10px",
  md: "12px",
  lg: "14px",
};

const MESSAGE_FONT_SIZES = {
  sm: "14px",
  default: "16px",
  md: "18px",
  lg: "20px",
};

type MessageWithLink = {
  isbentotheme: string;
  interstyle: {
    colors: {
      highlight: string;
    };
  };
};

const MessageWithLink = styled.p<MessageWithLink>`
  & a {
    color: ${({ isbentotheme, interstyle }: MessageWithLink) =>
      isbentotheme === "true" || interstyle.colors.highlight.at(0) !== "#"
        ? "blue"
        : Color(interstyle.colors.highlight).lighten(0.5).hex()} !important;
  }
`;

function Message({
  isHighlighted,
  isCensored,
  content,
  userId,
  messageId,
  loggedInUserId,
  handleReplyClick,
  createdAt,
  displayPhotoUrl,
  userStatus,
  messageRef,
}: MessageProps) {
  const {
    chat: chatStyle,
    interactivity: interStyle,
    isBentoTheme,
  } = useThemeContext();

  const chatBackground = chatStyle.colors.background;
  const pictureBackgroundColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? Color("#94A1B299").lighten(0.45).hex()
      : Color(chatBackground).darken(0.7).hex();
  const usernameColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#6B7280"
      : Color(chatBackground).darken(0.7).hex();
  const timeColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#A3A9B7"
      : Color(chatBackground).darken(0.7).hex();
  const fontFamily = chatStyle?.font?.style || "Poppins";
  const replyButtonColor =
    isBentoTheme || chatBackground.at(0) !== "#"
      ? "#ABC6D7"
      : Color(chatBackground).darken(0.7).hex();
  const { hasPausedUsersEnabled, hasBannedWordsEnabled } = useFeatureFlag();
  const [avatarError, setAvatarError] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.69,
  });
  const { bannedWords } = useChatContext();
  const { data: participantData } = useBatchGetParticipantById(userId);
  const { data: currUserReactionData, isLoading: currUserReactionLoading } =
    useBatchGetCurrUserReactionByMessageId(messageId, loggedInUserId ?? "");
  const firstNameFirstLetter =
    participantData?.data?.firstName?.charAt(0) ?? "";
  const lastNameLastLetter = participantData?.data?.lastName?.charAt(0) ?? "";

  const handleReplyClickWrapper = useCallback(() => {
    if (handleReplyClick) {
      handleReplyClick({
        parentMessageId: messageId,
        userId: userId,
        content: message,
        firstName: participantData?.data?.firstName ?? "",
        lastName: participantData?.data?.lastName ?? "",
        displayPhotoUrl,
        createdAt,
        isCensored: isCensored ?? false,
      });
      const e = document.getElementById("chat") as HTMLElement;
      e.focus();
    }
  }, [participantData]);

  const userName = `${participantData?.data?.firstName} ${participantData?.data?.lastName}`;
  const formattedTime = toAmPmFormat(createdAt);

  const picture = (
    <div
      className="flex-shrink-0 h-10 w-10 rounded-full mt-2 overflow-hidden"
      style={{
        backgroundColor: pictureBackgroundColor,
      }}
    >
      {!displayPhotoUrl || avatarError ? (
        <span
          className="flex items-center justify-center h-full w-full font-bold uppercase"
          style={{
            fontSize:
              PROF_INITIALS_FONT_SIZES[
                chatStyle?.font?.size as keyof typeof PROF_INITIALS_FONT_SIZES
              ] || "14px",
            fontFamily,
            color: isBentoTheme
              ? Color("#FFFFFF").darken(0.7).hex()
              : "#FFFFFF",
          }}
        >
          {firstNameFirstLetter}
          {lastNameLastLetter}
        </span>
      ) : (
        <img
          src={displayPhotoUrl}
          alt="avatar"
          onError={() => {
            console.error("[tako] error loading avatar");
            setAvatarError(true);
          }}
        />
      )}
    </div>
  );

  const [isClamped, setIsClamped] = useState(false);
  const spanRef = useRef<HTMLDivElement | null>(null);
  const toggleExpand = () => {
    setIsClamped((prev) => !prev);
  };
  const message =
    hasBannedWordsEnabled && bannedWords
      ? clean(content, bannedWords)
      : content;
  const [isMobile, setIsMobile] = useState(false);

  const resizeMountedRef = useRef(false);

  useEffect(() => {
    resizeMountedRef.current = true;

    setTimeout(() => {
      if (resizeMountedRef.current) {
        setIsClamped(true);
      }
    }, 5000);

    const handleResize = () => {
      if (resizeMountedRef.current) {
        setIsMobile(window.innerWidth <= 768);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      resizeMountedRef.current = false;
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDisplayScrollDownButton = () => {
    if (isMobile) {
      const spanElement = spanRef.current;
      if (spanElement && spanElement?.clientHeight >= 48 && isHighlighted) {
        setIsClamped(true);
      }
    }
  };

  if (isMobile && isHighlighted && messageRef) {
    messageRef.current?.addEventListener(
      "touchmove",
      handleDisplayScrollDownButton
    );
  }

  return (
    <div ref={ref} className="w-full">
      {!inView && isHighlighted && (
        <MessageHighlight
          interStyle={interStyle}
          isBentoTheme={isBentoTheme}
          userName={`${participantData?.data?.firstName ?? ""} ${
            participantData?.data?.lastName ?? ""
          }`}
          isMobile={isMobile}
          isClamped={isClamped}
          isHighlighted={isHighlighted}
          isCensored={isCensored ?? false}
          spanRef={spanRef}
          message={message}
          toggleExpand={toggleExpand}
        />
      )}
      <div className="hover:bg-[#FFFFFF80] group/chat relative">
        <div className="absolute flex items-center group-hover/chat:!visible invisible w-15 right-5 bottom-1">
          <div
            className="border-2 group first:rounded-tl-md first:rounded-bl-md h-10 w-10 hover:bg-slate-200 z-[2] cursor-pointer"
            onClick={handleReplyClickWrapper}
            style={{
              borderColor: replyButtonColor,
              fill: replyButtonColor,
            }}
          >
            <BsReplyFill
              size={26}
              className="group-hover:fill-white m-1"
              style={{
                fill: isBentoTheme ? "#ABC6D7" : replyButtonColor,
              }}
            />
          </div>
          <ReactionPopover
            loggedInUserId={loggedInUserId}
            messageId={messageId}
            userId={userId}
            currUserReaction={currUserReactionData?.reactions[0]}
            disabled={!!hasPausedUsersEnabled && userStatus === "PAUSED"}
          />
        </div>
        <div className={`flex w-full m-2 space-x-3 p-2`}>
          {picture}
          <div className="flex-1 break-words">
            <MessageUsername participantId={userId} />
            <span
              className="text-[10px] font-[450] ml-1"
              style={{
                color: timeColor,
                fontSize:
                  TIME_FONT_SIZES[
                    chatStyle?.font?.size as keyof typeof TIME_FONT_SIZES
                  ] || "10px",
                fontFamily: chatStyle?.font?.style || "Poppins",
              }}
            >
              {formattedTime}
            </span>
            <div
              className={isHighlighted ? "rounded-lg mr-2 text-white" : ""}
              style={{
                border: isHighlighted
                  ? `solid 4px ${interStyle.colors.highlight || "#f1d4a3"}4D`
                  : "unset",
              }}
            >
              <div
                className="flex text-dark w-full max-w-full break-words relative items-center gap-2 rounded-md "
                style={{
                  backgroundColor: isHighlighted
                    ? isBentoTheme
                      ? "#DB8700"
                      : `${interStyle.colors.highlight}`
                    : "unset",
                  padding: isHighlighted ? "0.5rem 0.75rem" : "unset",
                }}
              >
                {isHighlighted && (
                  <BsPinAngleFill size={12} className="flex-none" />
                )}
                <MessageWithLink
                  style={{
                    wordBreak: "break-word",
                    fontFamily: chatStyle?.font?.style || "Poppins",
                    color: isCensored
                      ? isHighlighted
                        ? "white"
                        : "red"
                      : isHighlighted
                      ? "white"
                      : isBentoTheme
                      ? "black"
                      : chatStyle?.font?.color,
                    fontSize:
                      MESSAGE_FONT_SIZES[
                        chatStyle?.font?.size as keyof typeof MESSAGE_FONT_SIZES
                      ] || "16px",
                  }}
                  isbentotheme={`${isBentoTheme}`}
                  interstyle={interStyle}
                >
                  {!isCensored ? (
                    <span dangerouslySetInnerHTML={{ __html: message }}></span>
                  ) : (
                    "This message has been flagged inappropriate."
                  )}
                </MessageWithLink>
              </div>
            </div>
            <MessageReactions
              loggedInUserId={loggedInUserId}
              messageId={messageId}
              currUserReaction={currUserReactionData?.reactions[0]}
              disabled={!!hasPausedUsersEnabled && userStatus === "PAUSED"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;

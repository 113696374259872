import Color from "color";
import { useThemeContext } from "../utils/themes";
import { ReactNode } from "react";
import { useBatchGetParticipantById } from "../queries/participants";

const PROF_INITIALS_FONT_SIZES = {
  sm: "10px",
  default: "12px",
  md: "14px",
  lg: "16px",
};

const getDisplayNameInitials = (
  firstName: string | undefined,
  lastName: string | undefined
): ReactNode => {
  const fn = firstName && firstName.length > 0 ? firstName[0] : "";
  const ln = lastName && lastName.length > 0 ? lastName[0] : "";
  return (
    <>
      <span className="uppercase">{fn}</span>
      <span className="uppercase">{ln}</span>
    </>
  );
};

export default function MessageUserInitials({
  participantId,
}: {
  participantId: string;
}) {
  const {
    chat: chatStyle,
    isBentoTheme,
    interactivity: interStyle,
  } = useThemeContext();
  const chatBackground = chatStyle.colors.background;
  const fontFamily = chatStyle.font.style || "Poppins";
  const { data: parentMessage, isLoading } =
    useBatchGetParticipantById(participantId);

  return (
    <span
      className="flex items-center justify-center h-full w-full font-bold text-white"
      style={{
        fontFamily,
        fontSize:
          PROF_INITIALS_FONT_SIZES[
            chatStyle.font.size as keyof typeof PROF_INITIALS_FONT_SIZES
          ] || "10px",
        color: isBentoTheme ? Color("#FFFFFF").darken(0.7).hex() : "#FFFFFF",
      }}
    >
      {getDisplayNameInitials(
        parentMessage?.data?.firstName,
        parentMessage?.data?.lastName
      )}
    </span>
  );
}

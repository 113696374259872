import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Interactivities } from "./interactivity/types";
import { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";
import { deObf, makeItemKey, obf } from "./utils/helper";

const STORAGE_ITEMS = {
  ACCESS_ID: "tako0",
  SECRET_KEY: "tako1",
  STS_TOKEN: "tako2",
  IDENTITY_ID: "tako3",
};

const sessionStoreEncrypted: SyncStorage<string> = {
  getItem: (key, initialValue) => {
    const storedValue = sessionStorage.getItem(key);

    if (storedValue) {
      const newValue = deObf(JSON.parse(storedValue));
      return newValue;
    }

    return initialValue;
  },
  setItem: (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(obf(value)));
  },
  removeItem: (key) => {
    sessionStorage.removeItem(key);
  },
};

const sessionStore: SyncStorage<string> = {
  getItem: (key, initialValue) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue || initialValue;
  },
  setItem: (key, value) => {
    sessionStorage.setItem(
      key,
      typeof value !== "string" ? JSON.stringify(value) : value
    );
  },
  removeItem: (key) => {
    sessionStorage.removeItem(key);
  },
};

// tako config
export const userIdAtom = atomWithStorage(makeItemKey("userId"), "");
export const sessionIdAtom = atomWithStorage(makeItemKey("sessionId"), "");
export const correlationIdAtom = atomWithStorage(
  makeItemKey("correlationId"),
  ""
);
export const connectionIdAtom = atomWithStorage(
  makeItemKey("connectionId"),
  ""
);

export const accessKeyIdAtom = atomWithStorage(
  makeItemKey(STORAGE_ITEMS.ACCESS_ID),
  "",
  sessionStoreEncrypted
);
export const secretKeyAtom = atomWithStorage(
  makeItemKey(STORAGE_ITEMS.SECRET_KEY),
  "",
  sessionStoreEncrypted
);
export const stsTokenAtom = atomWithStorage(
  makeItemKey(STORAGE_ITEMS.STS_TOKEN),
  "",
  sessionStoreEncrypted
);
export const identityIdAtom = atomWithStorage(
  makeItemKey(STORAGE_ITEMS.IDENTITY_ID),
  "",
  sessionStoreEncrypted
);
export const takoExpiresAtAtom = atomWithStorage(
  makeItemKey("takoExpiresAt"),
  "",
  sessionStore
);

// interactivity
export const selectedAnswerStore = atomWithStorage(
  makeItemKey("selectedAnswer"),
  null as string | null
);
export const instanceIdStore = atomWithStorage(
  makeItemKey("currentInstanceId"),
  ""
);
export const interactivitiesStore = atomWithStorage(
  makeItemKey("interactivities"),
  null as Interactivities | null
);

// prompt state
export const isPromptStore = atomWithStorage(makeItemKey("isPrompt"), false);
export const isParticipantIdleStore = atomWithStorage(
  makeItemKey("isParticipantIdle"),
  true
);
export const isPromptMessageStore = atomWithStorage(
  makeItemKey("isPromptMessage"),
  ""
);
export const isAgreementModalStore = atomWithStorage(
  makeItemKey("isAgreementModal"),
  false
);
export const isFirstTimeLoggedInStore = atomWithStorage(
  makeItemKey("isFirstTimeLoggedIn"),
  true
);
export const isPromptMissedSceneStore = atomWithStorage(
  makeItemKey("isPromptMissedScene"),
  false
);

// unpersisted state
export const interactivityStore = atom<{
  interactivityId: string;
  type: string;
  sceneId: string;
} | null>(null);

export const sourceStore = atomWithStorage(makeItemKey("source"), "");
